// theme constant
export const drawerWidth = 260;
export const appDrawerWidth = 320;

// image constant
export const ONE_MEGE_TO_BYTE = 1000000; // 1M :1000000 Byte;
export const IMAGE_MAX_FILE_SIZE = ONE_MEGE_TO_BYTE;
export const IMAGE_MAIN_MAX_WIDTH = 1920;
export const IMAGE_MAIN_MAX_HEIGHT = 1080;
export const IMAGE_MENU_MAX_WIDTH = 700;
export const IMAGE_MENU_MAX_HEIGHT = 700;

// local storage
export const LOCAL_STORAGE_KEY = 'Inventory#Storage$KEY_00';

// date format
export const DATE_FORMAT = 'dd-MM-yyyy';

import { lazy } from 'react';
// config
import { path } from 'config/path';
// project imports
import MainLayout from 'layout/MainLayout';
import Loadable from 'components/ui/Loadable';
import PrivateRoute from './PrivateRoute';

// user routing
const UserProfile = Loadable(lazy(() => import('pages/user/Profile')));

const User = {
  path: path.urls.default,
  element: <MainLayout />,
  children: [
    {
      path: path.urls.profile,
      element: <PrivateRoute component={UserProfile} />,
    },
  ],
};

export default User;

import { gql } from '@apollo/client';

export const ADMIN_STORES_QUERY = gql`
  query GetStores {
    admin_stores {
      response {
        status
        message
      }
      items {
        id
        user_id
        key
        name
        user_name
        created
      }
    }
  }
`;

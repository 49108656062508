import { lazy } from 'react';
// config
import { path } from 'config/path';
// project imports
import MainLayout from 'layout/MainLayout';
import Loadable from 'components/ui/Loadable';
import PrivateRoute from './PrivateRoute';

// setting routing
const SettingStore = Loadable(lazy(() => import('pages/setting/Store')));
const SettingContent = Loadable(lazy(() => import('pages/setting/Content')));
const SettingMember = Loadable(lazy(() => import('pages/setting/Member')));
const SettingMembers = Loadable(lazy(() => import('pages/setting/Members')));

const User = {
  path: path.urls.default,
  element: <MainLayout />,
  children: [
    {
      path: path.urls.settingStore,
      element: <PrivateRoute component={SettingStore} />,
    },
    {
      path: path.urls.settingContent,
      element: <PrivateRoute component={SettingContent} />,
    },
    {
      path: path.urls.settingMembers,
      element: <PrivateRoute component={SettingMembers} />,
    },
    {
      path: path.urls.settingMember,
      element: <PrivateRoute component={SettingMember} />,
    },
    {
      path: `${path.urls.settingMember}/:id`,
      element: <PrivateRoute component={SettingMember} />,
    },
  ],
};

export default User;

import { lazy } from 'react';
// config
import { path } from 'config/path';
// project imports
import Loadable from 'components/ui/Loadable';
import MinimalLayout from 'layout/MinimalLayout';

const Login = Loadable(lazy(() => import('pages/auth/Login')));

const Authentication = {
  path: path.urls.default,
  element: <MinimalLayout />,
  children: [
    {
      path: path.urls.login,
      element: <Login />,
    },
  ],
};

export default Authentication;

import { useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';
// material
import {
  Button,
  FormControl,
  InputLabel,
  Menu,
  MenuItem,
  Select,
  Stack,
  Typography,
} from '@mui/material';
// components
import { Brand, DateRange, InOutMember } from 'components/app';
import { KeyboardArrowDown } from '@mui/icons-material';
import { IconPrinter, IconTrash } from '@tabler/icons';

const IoFilter = ({
  brand,
  io,
  member,
  date,
  datetype,
  allitem = false,
  handleChangeBrand,
  handleChangeIo,
  handleChangeMember,
  handleChangeDate,
  handleChangeDateType,
  printQty,
  handleClickPrint,
  handleResetDate,
  isDateSameAsInitDate,
  setSelected,
}) => {
  const [printOpen, setPrintOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  const handlePrintOpen = (event) => {
    setAnchorEl(event.currentTarget);
    setPrintOpen(true);
  }
  const handlePrintClose = () => {
    setAnchorEl(null);
    setPrintOpen(false);
  }

  return (
    <Stack direction={{ xs: 'column', sm: 'row' }} spacing={1}>
      <FormControl
        variant={isMobile ? 'standard' : 'outlined'}
        sx={{ minWidth: 120 }}
      >
        <InputLabel>Date Type</InputLabel>
        <Select
          label='Date Type'
          autoWidth
          value={datetype}
          onChange={handleChangeDateType}
        >
          <MenuItem value='all-items'>All</MenuItem>
          <MenuItem value='purchase'>Purchase</MenuItem>
          <MenuItem value='inout'>Date</MenuItem>
        </Select>
      </FormControl>
      {datetype !== 'all-items' && (
        <DateRange
          date={date}
          handleDate={handleChangeDate}
          handleResetDate={handleResetDate}
          isDateSameAsInitDate={isDateSameAsInitDate}
        />
      )}
      <Brand allitem={allitem} value={brand} handleChange={handleChangeBrand} />
      <FormControl
        variant={isMobile ? 'standard' : 'outlined'}
        sx={{ minWidth: 120 }}
      >
        <InputLabel>IN/OUT</InputLabel>
        <Select label='IN/OUT' autoWidth value={io} onChange={handleChangeIo}>
          <MenuItem value='all'>All</MenuItem>
          <MenuItem value='in'>In</MenuItem>
          <MenuItem value='out'>Out</MenuItem>
          <MenuItem value='order'>Order</MenuItem>
          <MenuItem value='in_order'>In & Order</MenuItem>
        </Select>
      </FormControl>
      <InOutMember
        allitem={allitem}
        value={member}
        handleChange={handleChangeMember}
      />
      {!isMobile && (
        <>
          <Button
            variant='contained'
            sx={{ height: 51.13, borderRadius: 3 }}
            onClick={handlePrintOpen}
            endIcon={<KeyboardArrowDown />}
          >
            Print {printQty && `(${printQty})`}
          </Button>
          <Menu
            open={printOpen}
            anchorEl={anchorEl}
            onClose={handlePrintClose}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            transformOrigin={{ vertical: 'top', horizontal: 'right' }}
          >
            <MenuItem sx={{color: 'primary.main'}} onClick={() => {handleClickPrint(); handlePrintClose()}}>
              <Stack direction='row' spacing={1} alignItems='center'>
                <IconPrinter/>
                <Typography>Print {printQty && `(${printQty})`}</Typography>
              </Stack>
            </MenuItem>
            <MenuItem sx={{color: 'error.main'}} onClick={() => {setSelected([]); handlePrintClose()}}>
              <Stack direction='row' spacing={1} alignItems='center'>
                <IconTrash/>
                <Typography>Clear Print</Typography>
              </Stack>
            </MenuItem>
          </Menu>
        </>
      )}
    </Stack>
  );
};

export default IoFilter;

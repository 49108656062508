// icon
import { IconSettings } from '@tabler/icons';
// config
import { path } from 'config/path';

const setting = {
  title: 'Management',
  type: 'group',
  level: 'client',
  children: [
    {
      id: 'setting',
      title: 'Setting',
      type: 'collapse',
      icon: IconSettings,
      children: [
        {
          id: 'store',
          title: 'Store',
          type: 'item',
          url: path.urls.settingStore,
        },
        {
          id: 'content',
          title: 'Content',
          type: 'item',
          url: path.urls.settingContent,
        },
        {
          id: 'members',
          title: 'Members',
          type: 'item',
          url: path.urls.settingMembers,
        },
      ],
    },
  ],
};

export default setting;

import { Typography } from "@mui/material";

export const TermsAndConditions = () => {
  return (
    <>
      <Typography variant="h1" sx={{ pb:'0.5em'}}>Term & Condition</Typography>
      <Typography variant="body1">
        Our photographs are an accurate representation of our cakes however as each cake is a handmade “one off” creation details may not be exactly as shown. Please note that when ordering a cake using one of our photos as reference that the size of the cake may alter the proportions.<br />
        All of our cakes are baked fresh to order and as such we require a minimum of 2 days notice (Fresh cream cakes) / 5 days notice (Buttercream cakes) orders. Our cakes can sometimes be made with shorter lead times
        but please call the shop to enquire if this is possible.<br /><br />
      </Typography>
      <Typography variant="h3" sx={{ pb:'0.5em'}}>Cake cancellations</Typography>
      <Typography variant="body1">
        <Typography component="span" color='red' sx={{ fontWeight:700 }}>
        Fresh cream cake; 24 hours before or more - refund or credit<br />
        Buttercream cake; 48 hours before or more - refund or credit<br />
        Less than 24 hours notice – no refund/credit<br />
        </Typography>
        Please note that orders placed without giving enough notice may not be ready for collection on time. If you are unhappy with one of our products, we require to be notified within 2 hours after collection via phone call or visit to the shop.<br />
        If you believe a cake to be damaged are unhappy with any aspect of the cake, we may require photographs showing the condition of the cake.<br />
        Please note that whilst refunds can be made, compensation will not exceed the original total paid for cakes.<br /><br />
      </Typography>

      <Typography variant="h3" sx={{ pb:'0.5em'}}>Allergy information<br/></Typography>      
      <Typography>
        Our cakes are made fresh, daily in our bakery and are non-pre-packaged. <br/>
        Our cakes are made in a bakery where traces of <b>NUTS</b> may be.<br/>
        All our cakes contain<b> EGGS, MILK </b>and may contain traces of <b>NUTS</b>
        <br />
        Please speak to a member of our team for more information about allergens and ingredients.<br /><br />
      </Typography>
    </>
  )
}

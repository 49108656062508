import { gql } from '@apollo/client';

export const CATEGORIES_QUERY = gql`
  query GetCategories($storeId: ID!) {
    currentStoreId @client @export(as: "storeId")
    categories(store_id: $storeId) {
      response {
        status
        code
        message
      }
      items {
        category
      }
    }
  }
`;

export const SUBCATEGORIES_QUERY = gql`
  query GetSubcategories($storeId: ID!, $categories: String) {
    currentStoreId @client @export(as: "storeId")
    subcategories(store_id: $storeId, categories: $categories) {
      response {
        status
        code
        message
      }
      items {
        category
        subcategory
      }
    }
  }
`;

import { Box, Typography } from "@mui/material";
import { useContext } from "react";
import { StoreContext } from "layout/HomeLayout";

const Privacy = () => {
  const { store } = useContext(StoreContext); 
  return (
    <Box display='block' sx={{ p:'2em 1em'}}>
      <Typography variant="h1" sx={{ pb:'1em'}}>Privacy Policy</Typography>
      <Typography>
        At {store.company}, we value our customers' trust and respect their privacy.<br/>
        This Privacy Policy outlines how we collect, use, and protect the personal
        information you provide to us when making an online order.<br/><br/>
      </Typography>
      <Typography variant="h3">What Personal Information Do We Collect?</Typography>
      <Typography>
        When you place an online order with us, we collect the following personal
        information:<br/><br/>

        * Email address<br/>
        * Phone number<br/><br/>

        We only collect this information to facilitate communication regarding your
        order, including updates on order status and special promotions or offers.<br/>
        We do not share your email address or phone number with any third-party
        companies.<br/><br/>
      </Typography>

      <Typography variant="h3">How Do We Use Your Personal Information?</Typography>
      <Typography>
        We use your personal information for the following purposes:<br/><br/>

        1. To process and fulfill your online orders.<br/>
        2. To send you updates on your order status, including delivery
        confirmation and any changes to your order.<br/>
        3. To inform you about special promotions or offers that may be of interest
        to you.<br/><br/>
      </Typography>
      <Typography variant="h3">How Do We Protect Your Personal Information?</Typography>
      <Typography>
        We take the security of your personal information seriously. We use
        industry-standard encryption and secure servers to protect your data from
        unauthorized access. Our staff is trained to handle sensitive customer
        information with care, and we maintain strict access controls to prevent
        unauthorized access to our systems.<br/><br/>
      </Typography>
      <Typography variant="h3">What Are Your Rights Under This Policy?</Typography>
      <Typography>
        You have the right to:<br/><br/>

        1. Access: Request a copy of your personal information that we hold.<br/>
        2. Rectify: Correct any inaccuracies in your personal information.<br/>
        3. Erase: Delete your personal information, subject to certain conditions
        (e.g., if you have an outstanding order).<br/>
        4. Restrict: Limit our use and disclosure of your personal information.<br/><br/>
      </Typography>
      <Typography variant="h3">How Long Do We Retain Your Personal Information?</Typography>
      <Typography>
        We retain your personal information for as long as necessary to fulfill the
        purposes outlined in this Policy, or until you request that we erase it. If
        you have any questions or concerns about how we process your personal
        information, please contact us at {store.email}.<br/><br/>
      </Typography>
      <Typography variant="h3">Changes to This Policy</Typography>
      <Typography>
        We reserve the right to modify this Privacy Policy at any time. If we make
        material changes to this policy, we will notify you by email and update
        this page.<br/><br/>
      </Typography>
      <Typography variant="h3">Contact Us</Typography>
      <Typography>
        If you have any questions about our privacy practices or concerns about how
        we handle your personal information, please contact us at:<br/><br/>

        Email: {store.email}<br/>
        Phone: {store.phone}<br/><br/>

        Thank you for choosing {store.company}. We value your trust and look forward to
        serving you!<br/><br/>

        Last updated: 15 July 2024<br/><br/>

      </Typography>
    </Box>
  )
}

export default Privacy;
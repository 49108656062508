import { Accordion, AccordionDetails, AccordionSummary, Box, Grid,
	Breadcrumbs, Button, MenuItem, Select, Stack, TextField, Typography 
} from "@mui/material";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useContext, useState, useEffect } from "react";
import { fCurrency } from "utils/formatNumber";
import { ExpandMore } from "@mui/icons-material";
import { useTheme } from "@mui/material/styles";
import { StoreContext } from "../../layout/HomeLayout/index";
import { path } from "config/path";
import SwipeableViews from 'react-swipeable-views';
import { autoPlay } from 'react-swipeable-views-utils';
import MobileStepper from '@mui/material/MobileStepper';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import { ImgUpload } from "components/app/image";
import { v4 as uuidv4 } from 'uuid';
import { EditImage } from "utils/util";

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);
const CAT_COLOR_OPTION = ['signature cake', 'bespoke cake', 'mini cake', 'cupcake'];
const CAT_COLOR_OPTION2 = ['signature cake', 'bespoke cake', 'mini cake'];
const ADD_MINITOPPER = ['luxurymini', 'simplemini','luxuryminiset'];
const FLOWERCAKE = ['flowercake', 'fullmixedflowercake'];
const colors = [
	'yellow', 'orange', 'red', 'skyblue', 'deepskyblue', 'mediumblue', 'black',
	'pink', 'hotpink', 'violet', 'purple', 'lightgreen', 'green', 'ivory', 'beige',
	'brown', 'Others'
];

const all_flavours = [
	{ name: 'vanilla-strawberry', title: 'vanilla genoise, strawberry jam + Italian meringue buttercream', price: 0, type: 1 },
	{ name: 'vanilla-toffee', title: 'vanilla genoise. Toffee + Italian meringue buttercream', price: 2, type: 1 },
	{ name: 'choco-nutella', title: 'chocolate genoise. Nutella + Italian meringue buttercream', price: 5, type: 1 },
	{ name: 'choco-oreo', title: 'chocolate genoise. Oreo + Oreo cream', price: 5, type: 1 },
	{ name: 'vanilla-strawberry-sponge', title: 'vanilla sponge, strawberry jam + Italian meringue buttercream', price: 0, type: 2 },
	{ name: 'vanilla-toffee-sponge', title: 'vanilla sponge. Toffee + Italian meringue buttercream', price: 2, type: 2 },
	{ name: 'choco-nutella-sponge', title: 'chocolate sponge. Nutella + Italian meringue buttercream', price: 5, type: 2 },
	{ name: 'choco-oreo-sponge', title: 'chocolate sponge. Oreo + Oreo cream', price: 5, type: 2 }
];

const all_addcupcakes = [
	{ title: 'none', price: 0, type : 0 },
	{ title: '+6 Simple cupcakes', price: 20, type : 1 },
	{ title: '+12 Simple cupcakes', price: 38, type : 1 },
	{ title: '+6 Luxury cupcakes', price: 25, type : 1 },
	{ title: '+12 Luxury cupcakes', price: 50, type : 1 },
	{ title: '+6 Flower cupcakes', price: 30, type : 2 },
	{ title: '+12 Flower cupcakes', price: 58, type : 2 },
];

const caketypes = ['Event Type', 'Wedding Cake', 'Birthday Cake', 'Other Celebration Cake'];

// PRODUCT INFO
const ProductInfo = () => {
	// HOOKS
	const params = useParams();
	const theme = useTheme();
	const navigate = useNavigate();
	const { store, allmenu, cart, setCart } = useContext(StoreContext);

	// DATA
	const [quantity, setQuantity] = useState(1);
	const [products, setProducts] = useState([]);
	const [pidx, setPidx] = useState(0);
	const [selColor, setSelColor] = useState(colors[0]);
	const [selPointColor, setSelPointColor] = useState(colors[1]);
	const [cupCakes, setCupCakes] = useState(0);
	const [selFlavour, setSelFlavour] = useState(0);
	const [customColor, setCustomColor] = useState("");
	const [customPointColor, setCustomPointColor] = useState("");
	const [toppings, setToppings] = useState([]);
	const [miniTopper, setMiniTopper] = useState([]);
	const [selTopping, setSelTopping] = useState(0);	
	const [toppingNote, setToppingNote] = useState("");
	const [selMiniTopper, setSelMiniTopper] = useState(0);
	const [totalPrice, setTotalPrice] = useState(0);
	const [colorOption, setColorOption] = useState(false);
	const [colorOption2, setColorOption2] = useState(false);
	const [isCupCake, setIsCupCake] = useState(false);
	const [images, setImages] = useState([]);
	const [activeStep, setActiveStep] = useState(0);
	const [maxSteps, setMaxSteps] = useState(0);
	const [customMsg, setCustomMsg] = useState('');
	const [customImage, setCustomImage] = useState(false);
	const [customImages, setCustomImages] = useState([]);
	const [sessionId, setSessionId] = useState('');
	const [flavours, setFlavours] = useState([]);
	const [addcupcakes, setAddCupcakes] = useState([]);
	const [addTopperMsg, setAddTopperMsg] = useState(false);
	const [cakeType, setCakeType] = useState(0);

	// USE EFFECTS
	useEffect(() => {
		const selToppingObj = toppings.find((topping) => topping.id === selTopping);
		const cupcakePrice = addcupcakes[cupCakes]?.price || 0;
		const flavourPrice = flavours[selFlavour]?.price || 0;
		const toppingPrice = selToppingObj?.sale_price || 0;
		const cakePrice = products[pidx]?.sale_price || 0;
		setTotalPrice(quantity * (Number(cakePrice) + Number(cupcakePrice) + Number(flavourPrice) + Number(toppingPrice)));
		setAddTopperMsg(selTopping>0 && selToppingObj?.note.indexOf('Not available to add any message')<0);
	}, [toppings, selTopping, cupCakes, selFlavour, quantity, products, pidx]);

	useEffect(() => {
		async function getMenu() {
			const res = await fetch(`${path.ciHost}/index.php/api/v1/product/model/${store.id}/` + params.id);
			const data = await res.json();
			console.log(data);
			setPidx(0);
			if (params.id == 'blackpearl' || params.id == 'blackpink') {
				setColorOption(false);
				setColorOption2(false);
			}
			else {
				setColorOption(CAT_COLOR_OPTION.indexOf(data.products[0]?.subcategory) >= 0);
				setColorOption2(CAT_COLOR_OPTION2.indexOf(data.products[0]?.subcategory) >= 0);
			}
			//setMiniCake(data.products[0]?.subcategory == 'mini cake'); 
			setIsCupCake(data.products[0]?.subcategory == 'cupcake');
			if (FLOWERCAKE.indexOf(data.products[0].model) >= 0) {
				setAddCupcakes(all_addcupcakes.filter(item=>(item.type==0 || item.type==2)));
			}
			else {
				setAddCupcakes(all_addcupcakes.filter(item=>(item.type==0 || item.type==1)));
			}
			if (data.products[0]?.subcategory == 'bespoke cake') {
				const newId = uuidv4();
    		setSessionId(newId);
				setCustomImage(true);				
				setFlavours(all_flavours.filter(item=>(item.type==1)));
			}
			else if (data.products[0]?.subcategory == 'signature cake') {
				setFlavours(all_flavours.filter(item=>(item.type==2)));
			}
			setProducts(data.products);
			let imgs = [];
			// data.products.map((item, index) => {
			// 	if (item.image.length > 0) {
			// 		imgs.push(`${path.imageServer}${path.imageDirectory}/${store.key}/${item.image}`);
			// 	}
			// })
			data.products[0]?.image && imgs.push(`${path.imageServer}${path.imageDirectory}/${store.key}/${data.products[0].image}`);
			data.images.map((item, index) => {
				if (item.filename.length > 0) {
					imgs.push(`${path.ciHost}/uploads/${store.id}/${item.filename}`);
				}
			})
			//console.log(imgs);
			setImages(imgs);
			setMaxSteps(imgs.length);
		}
		getMenu();
	}, [params.id]);

	useEffect(() => {
		if ((products.length > 0) && (products[0].category == 'cake')) {
			const cat = allmenu.get('cake');
			if (cat) {		
				const topper = cat.get('topper');
				setToppings(topper);
				if (ADD_MINITOPPER.indexOf(products[0]?.model)>=0) {
					const miniTop = cat.get('topper2');
					//console.log(miniTop);
					setMiniTopper(miniTop);
				}
				else setMiniTopper([]);
			}
		}
	}, [products]);

	// FUNCTIONS
	const handleAddToCart = () => {
		console.log(customImages);
		const selToppingDetails = toppings.find((topping) => topping.id === selTopping);
		const selMiniToppingDetails = miniTopper.find((topping) => topping.id === selMiniTopper);
		let toppingDetails = null;
		if (selToppingDetails) {
			toppingDetails = {
				id: selTopping,
				size: selToppingDetails.size,
				sale_price: selToppingDetails.sale_price,
				image: selToppingDetails.image,
				note: toppingNote,
			};
		}
		if (selMiniToppingDetails) {
			if (toppingDetails == null) toppingDetails = {};
			toppingDetails.minitopper = selMiniToppingDetails.size;
			toppingDetails.minitopimg = selMiniToppingDetails.image;
		}
		const newCartItem = {
			...products[pidx],
			quantity,
			size: products[pidx].size,
			topping: toppingDetails,
			//color: selColor == 'Others' ? customColor : selColor,
			flavour: flavours.length>0 ? flavours[selFlavour].name : '',
			addonprice: flavours.length>0 ? Number(flavours[selFlavour].price) : 0
		};
		
		if (cupCakes > 0) {
			newCartItem.flavour += ', ' + addcupcakes[cupCakes].title;
			newCartItem.addonprice += Number(addcupcakes[cupCakes].price);
		}
		if (colorOption) {
			newCartItem.color = selColor == 'Others' ? customColor : selColor;
			if (selPointColor.length>0) {
				newCartItem.color += '/';
				newCartItem.color += selPointColor == 'Others' ? customPointColor : selPointColor;
			}
		}
		if (customImages.length>0) {
			let img = customImages[0];
			img.param = cart.length;
			img.type = 20000;
			EditImage(img);
			newCartItem.customImage = img;
		}
		if (cakeType>0) {
			newCartItem.customMsg = caketypes[cakeType];
		}
		else {
			newCartItem.customMsg = '';
		}
		if (customMsg!='') {
			newCartItem.customMsg += ':'+customMsg;
		} 
		console.log(newCartItem);
		setCart([...cart, newCartItem]);
		navigate(`/menu`);
	};

	const handleQty = (type) => {
		if (type === "add") {
			setQuantity(quantity + 1);
		} else {
			if (quantity>1) setQuantity(quantity - 1);
		}
	};

	const handleStepChange = (step) => {
		setActiveStep(step);
	};

	const handleNext = () => {
		setActiveStep((prevActiveStep) => prevActiveStep < (maxSteps - 1) ? prevActiveStep + 1 : 0);
	};

	const handleBack = () => {
		setActiveStep((prevActiveStep) => prevActiveStep == 0 ? maxSteps - 1 : prevActiveStep - 1);
	};

	const setUpdateImages = (newImgs) => {
		console.log(newImgs);
		setCustomImages(newImgs);
	}

	const handleSelSize = (idx) => {
		//console.log(idx);
		setPidx(idx);
		if (products[idx]?.image) {
			let imgs = images;
			imgs[0] = `${path.imageServer}${path.imageDirectory}/${store.key}/${products[idx].image}`;
			setImages(imgs);
		} 
	}

	// RENDER
	return products.length > 0 && products[pidx] ? (
		<Box>
			{/* BreadCrumbs */}
			<Breadcrumbs sx={{ pt: 2, px: 2 }}>
				{/* <Link to="/">Home</Link> */}
				<Link to={`/menu`}>Menu</Link>
				<Link to={`/menu/${products[pidx].subcategory}`}>
					<Typography sx={{ textTransform: "capitalize" }}>{products[pidx].subcategory}</Typography>
				</Link>
				<Typography sx={{ textTransform: "capitalize" }}>{products[pidx].name}</Typography>
			</Breadcrumbs>
			<Stack sx={{ p: 2 }} spacing={1} direction="row">
				{/* Title */}
				<Typography variant="h3">{products[pidx].name}</Typography>
				{/* Price */}
				<Typography variant="h3" fontWeight={"normal"}>
					£{fCurrency(products[pidx].sale_price)}
				</Typography>
			</Stack>
			{ products[0].name1.length > 0 && (
				<Typography variant="h4" sx={{ pl : 2, pb : 2 }}>{products[0].name1}</Typography>
			)}
			<Grid container spacing={2}>			
				{/* Left section */}
				<Grid item xs={12} md={6}>
					<Stack spacing={2}>
						<Box display='flex' sx={{ px: 2, overflow: 'hidden', flexFlow: 'column' }}>
							{images.length > 1 ? (
								<>
									<AutoPlaySwipeableViews
										interval={1000000}
										axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
										index={activeStep}
										onChangeIndex={handleStepChange}
										enableMouseEvents
									>
										{images?.map((image, index) => (
											<div key={index}>
												{Math.abs(activeStep - index) <= 2 ? (
													<Box
														sx={{
															display: 'flex',
															justifyContent: 'space-between',
															alignItems: 'center',
															overflow: "hidden",
														}}
													>
														<img src={image}
															alt={products[pidx].name}
															style={{
																width: "100%",
																//height: "auto", 
																objectFit: "cover",
																objectPosition: "center",
																transform: "scale(1.4)",
																transformOrigin: "center center",
															}}
														/>
													</Box>
												) : null}
											</div>
										))}
									</AutoPlaySwipeableViews>
									<MobileStepper
										steps={maxSteps}
										position="static"
										activeStep={activeStep}
										nextButton={
											<Button
												size="small"
												onClick={handleNext}
											//disabled={activeStep === maxSteps - 1}
											>
												Next
												<KeyboardArrowRight />
											</Button>
										}
										backButton={
											<Button size="small"
												onClick={handleBack}
											//disabled={activeStep === 0}
											>
												<KeyboardArrowLeft />
												Back
											</Button>
										}
									/>
								</>
							) : (
								<Box sx={{ overflowX:'hidden'}}>
								<Box display='flex'>
									<Box width='100%'>
										<div>
											<Box
												sx={{
													display: 'flex',
													justifyContent: 'space-between',
													alignItems: 'center',
													overflow: "hidden",
												}}
											>
												<img
													src={images.length == 1 ? images[0] : `${path.basename}/images/icon-product.svg`}
													alt={products[pidx].name}
													style={{
														width: "100%",
														//height: "auto",
														objectFit: "cover",
														objectPosition: "center",
														transform: "scale(1.4)",
														transformOrigin: "center center"
													}}
												/>
											</Box>
										</div>
									</Box>
								</Box>
								</Box>
							)
							}
						</Box>
						<Typography variant="h5" sx={{ textAlign: 'center' }}>* Creation details may not be exactly as shown.</Typography>
					</Stack>
				</Grid>
				{/* Right section */}
				<Grid item xs={12} md={6}>
					<Stack spacing={2} sx={{ mt: 2 }}>
						{/* Size Selection */}
						<Stack direction="column" spacing={1} sx={{ px: 2, overflowX: "auto" }}>
							<Typography sx={{}} variant="h4">
								Size : ({products[pidx].buy_url})
							</Typography>
							<Stack direction="row" spacing={1} useFlexGap sx={{ pl: '1em', flexWrap:'wrap' }}>
								{products.map((menu, index) => (
									<Button
										key={menu.size}
										variant="outlined"
										onClick={() => handleSelSize(index)}
										sx={{
											textTransform: "capitalize",
											borderRadius: 0,
											borderColor: index === pidx ? theme.palette.buttonBlack.main : theme.palette.grey[500],
											color: index === pidx ? theme.palette.buttonBlack.main : theme.palette.grey[500],
											"&:hover": { borderColor: theme.palette.buttonBlack.main, color: theme.palette.buttonBlack.main },
											width:'45%'
										}}>
										{menu.size}
									</Button>
								))}
							</Stack>
						</Stack>
						{(products[pidx].category == 'cake') && (
							<>
								<Stack direction="column" sx={{ px: 2 }} spacing={1}>
									{/* Custom Image */}
									{ customImage && (
										<>
											<Typography sx={{}} variant="h4">
												Custom Image
											</Typography>
											<ImgUpload prevImages={customImages} storeId={store.id} defImgType={10000} defNote={sessionId} setUpdateImages={setUpdateImages} />
										</>
									)}
									{/* Color */}
									{ colorOption && (
										<>
											<Typography sx={{}} variant="h4">
												Main Color : {selColor}
											</Typography>
											<Box width='100%' display='flex' sx={{
												flexWrap: 'wrap',
												alignItems: 'center',
												justifyItems: 'center',
												pl: '1em'
											}}>
												{colors.map((colour, index) => {
													return (
														<Box key={index} width='11%' height='4em' onClick={() => setSelColor(colour)} sx={{
															p: '0.1em',
															m: '0.5em',
															borderColor: 'inherit',
															borderStyle: colour == selColor ? 'dashed' : 'ridge',
															borderWidth: colour == selColor ? '3px' : '1px',
														}}>
															<Box width='100%' height='100%' display='flex' sx={{
																backgroundColor: colour == 'Others' ? 'inherit' : colour,
																alignItems: 'center',
																justifyContent: 'center',
																fontSize: '1.2em'
															}} >{colour == 'Others' ? 'Others' : ' '}</Box>
														</Box>
													)
												})}
											</Box>
											{selColor == 'Others' && (
												<TextField label="Color name" variant="outlined" value={customColor} onChange={(e) => setCustomColor(e.target.value)} />
											)}
											{ colorOption2 && (
												<>
													<Typography sx={{}} variant="h4">
														Point Color : {selPointColor}
													</Typography>
													<Box width='100%' display='flex' sx={{
														flexWrap: 'wrap',
														alignItems: 'center',
														justifyItems: 'center',
														pl: '1em'
													}}>
														{colors.map((colour, index) => {
															return (
																<Box key={index} width='11%' height='4em' onClick={() => setSelPointColor(colour)} sx={{
																	p: '0.1em',
																	m: '0.5em',
																	borderColor: 'inherit',
																	borderStyle: colour == selPointColor ? 'dashed' : 'ridge',
																	borderWidth: colour == selPointColor ? '3px' : '1px',
																}}>
																	<Box width='100%' height='100%' display='flex' sx={{
																		backgroundColor: colour == 'Others' ? 'inherit' : colour,
																		alignItems: 'center',
																		justifyContent: 'center',
																		fontSize: '1.2em'
																	}} >{colour == 'Others' ? 'Others' : ' '}</Box>
																</Box>
															)
														})}
													</Box>
													{selPointColor == 'Others' && (
														<TextField label="Color name" variant="outlined" value={customPointColor} onChange={(e) => setCustomPointColor(e.target.value)} />
													)}
												</>
											)}
										</>
									)}
									{(flavours.length>0) && (
										<>
											{/* Matching cupcakes */}
											<Typography sx={{}} variant="h4">
												Add matching cupcakes
											</Typography>
											<Select
												MenuProps={{ sx: { maxHeight: 600 } }}
												variant="outlined" value={cupCakes}
												onChange={(e) => setCupCakes(e.target.value)}
												sx={{
													'.MuiSelect-select': {
														whiteSpace: 'break-spaces' // Setting white-space to break-spaces
													}
												}}>
												{addcupcakes.map((cupcake, index) => {
													return (
														<MenuItem key={index} value={index} sx={{ whiteSpace: 'break-spaces' }}>
															<Typography>{cupcake.title}
																<Typography variant="span">{cupcake?.price > 0 ? ' (+£' + cupcake?.price + ')' : ''}</Typography>
															</Typography>
														</MenuItem>
													);
												})}
											</Select>
											{/* Flavours */}
											<Typography sx={{}} variant="h4">
												Flavours
											</Typography>
											<Select
												//MenuProps={{ sx: { maxHeight: 600 } }} 
												inputProps={{ sx: { whiteSpace: 'normal' } }}
												variant="outlined"
												value={selFlavour}
												onChange={(e) => setSelFlavour(e.target.value)}
												sx={{
													width: '100%',
													maxWidth: '95vw',
													'.MuiSelect-select': {
														whiteSpace: 'break-spaces' // Setting white-space to break-spaces
													}
												}}
											>
												{flavours.map((flavour, index) => {
													return (
														<MenuItem key={index} value={index} sx={{ whiteSpace: 'break-spaces' }}>
															<Typography
																sx={{
																	whiteSpace: "normal", 
																	wordWrap: "break-word", 
																	overflow: "hidden",
																}}
															>
																{flavour.title}
																<Typography variant="span">{flavour?.price > 0 ? ' (+£' + flavour?.price + ')' : ''}</Typography>
															</Typography>
														</MenuItem>
													);
												})}
											</Select>
										</>
									)}
									{/* Topper */}
									{!isCupCake && (
										<>
											<Typography sx={{}} variant="h4">
												Additional Topper
											</Typography>
											<Select MenuProps={{ sx: { maxHeight: 600 } }} variant="outlined" value={selTopping} onChange={(e) => setSelTopping(e.target.value)}>
												<MenuItem value={0}>No Topper</MenuItem>
												{toppings.map((topping) => {
													return (
														<MenuItem key={topping.id} value={topping.id}>
															<Grid container spacing={1}>
																<Grid item xs={6}>
																	<Box
																		component="img"
																		src={`${path.imageServer}${path.imageDirectory}/${store.key}/${topping?.image}`} 
																		alt={topping?.name}
																		sx={{ width: "100%" }}
																	/>
																</Grid>
																<Grid item xs={6} sx={{ 
																	display:'flex',
																	flexDirection:'column',
																	justifyContent:'center'
																}}>
																	<Stack spacing={1}>
																		<Typography
																			sx={{
																				whiteSpace: "normal", 
																				wordWrap: "break-word", 
																				overflow: "hidden",
																			}}
																		>
																			* {topping?.note}
																		</Typography>
																		<Typography sx={{
																				whiteSpace: "normal", 
																				wordWrap: "break-word", 
																				overflow: "hidden",
																			}}>
																				* {topping?.buy_url}
																		</Typography> 
																		<Typography>{topping?.sale_price>0 ? '* price : £'+topping.sale_price : ''}</Typography>
																	</Stack>
																</Grid>
															</Grid>
														</MenuItem>
													);
												})}
											</Select>
											{addTopperMsg && (
												<TextField label={`Topper Note - Max 20 letters`} multiline rows={3} variant="outlined" value={toppingNote} onChange={(e) => setToppingNote(e.target.value)} />
											)}
											{/* Mini Topper */}
											{ (miniTopper.length>0) && (
												<>
													<Typography sx={{}} variant="h4">
														Mini Topper
													</Typography>
													<Select MenuProps={{ sx: { maxHeight: 600 } }} variant="outlined" value={selMiniTopper} onChange={(e) => setSelMiniTopper(e.target.value)}>
														<MenuItem value={0}>No Mini Topper</MenuItem>
														{miniTopper.map((topping) => {
															return (
																<MenuItem key={topping.id} value={topping.id}>
																	<Stack direction="row" spacing={2} alignItems="center">
																		<Box>
																			<img src={`${path.imageServer}${path.imageDirectory}/${store.key}/${topping?.image}`} alt={topping?.name} style={{ width: 200, height: 200 }} />
																		</Box>
																		<Box display='block' width='40%' height='100%' sx={{ maxWidth: '50px' }}>
																			{/* <Typography variant="p">{topping?.note}</Typography> */}
																			{/* <Typography variant="span">{topping?.buy_url}</Typography> */}
																			<Typography>{topping?.sale_price>0 ? '£'+topping.sale_price : ''}</Typography>
																		</Box>
																	</Stack>
																</MenuItem>
															);
														})}
													</Select>
												</>
											)}
										</>
									)}			
									<Select
										inputProps={{ sx: { whiteSpace: 'normal' } }}
										variant="outlined"
										value={cakeType}
										onChange={(e) => setCakeType(e.target.value)}
										sx={{
											width: '100%',
											maxWidth: '95vw',
											'.MuiSelect-select': {
												whiteSpace: 'break-spaces' 
											}
										}}
									>
										{caketypes.map((type, index) => {
											return (
												<MenuItem key={index} value={index} sx={{ whiteSpace: 'break-spaces' }}>
													<Typography
														sx={{
															whiteSpace: "normal", 
															wordWrap: "break-word", 
															overflow: "hidden",
														}}
													>
														{type}
													</Typography>
												</MenuItem>
											);
										})}
									</Select>
									{(params.id == 'fondantletters') && (
										<TextField label={`Custom message\n(Max 20 letters)`} multiline rows={3} variant="outlined" value={customMsg} onChange={(e) => setCustomMsg(e.target.value)} />
									)}
								</Stack>
							</>
						)}
						{/* Quantity */}
						<Stack direction="column" sx={{ px: 2 }} spacing={1}>
							<Typography sx={{}} variant="h4">
								Quantity
							</Typography>
							<Stack direction="row" sx={{ "& > *": { width: 40, height: 40 }, "& button": { fontSize: 20, background: theme.palette.background.default } }}>
								<button onClick={() => handleQty("minus")}>-</button>
								<Typography sx={{ border: "1px solid", display: "flex", justifyContent: "center", alignItems: "center" }}>{quantity}</Typography>
								<button onClick={() => handleQty("add")}>+</button>
							</Stack>
						</Stack>
						{/* Add to cart button */}
						<Box sx={{ px: 2 }}>
							<Button
								fullWidth
								variant="outlined"
								sx={{
									borderRadius: 0,
									borderColor: theme.palette.buttonBlack.main,
									"&:hover": { background: theme.palette.buttonBlack.main, borderColor: theme.palette.buttonBlack.main },
									p: 0.3,
								}}
								onClick={handleAddToCart}>
								<Box
									sx={{
										border: "1px solid",
										borderColor: theme.palette.buttonBlack.main,
										width: "100%",
										height: "100%",
										background: theme.palette.buttonBlack.main,
										color: theme.palette.background.default,
									}}>
									<Typography sx={{ p: 1.5, fontWeight: "bold" }}>Add to cart - £{fCurrency(totalPrice)}</Typography>
								</Box>
							</Button>
						</Box>
						<Stack
							sx={{
								"& .MuiAccordion-root": {
									borderTop: "1px solid",
									borderColor: theme.palette.grey[500],
									"&::before": { display: "none" },
									"&:last-child": { borderBottom: "1px solid", borderColor: theme.palette.grey[500] },
								},
							}}>
							{/* Description */}
							{products[pidx].note && (
								<Accordion disableGutters square defaultExpanded>
									<AccordionSummary expandIcon={<ExpandMore />}>
										<Typography variant="h4">Description</Typography>
									</AccordionSummary>
									<AccordionDetails>
										<Typography sx={{ textAlign: "justify" }} variant="body1">
											{products[pidx].note}
										</Typography>
									</AccordionDetails>
								</Accordion>
							)}
							{/* Ingredients */}
							{products[pidx]?.site_url && (
								<Accordion disableGutters square>
									<AccordionSummary expandIcon={<ExpandMore />}>
										<Typography variant="h4">Ingredients</Typography>
									</AccordionSummary>
									<AccordionDetails>
										<Typography sx={{ textAlign: "justify" }} variant="body1">
											{products[pidx].site_url}
										</Typography>
									</AccordionDetails>
								</Accordion>
							)}
							<Accordion disableGutters square>
								<AccordionSummary expandIcon={<ExpandMore />}>
									<Typography variant="h4">Allergy and Safety Information</Typography>
								</AccordionSummary>
								<AccordionDetails>
									<Typography sx={{ textAlign: "justify" }} variant="body1" fontWeight="700">
										For your transparency and safety, please be aware of the following:
										<br />
										* Nut Traces: All our cakes are prepared in a bakery where traces of nuts may be present.
										<br />
										* Ingredients: All our cakes contain eggs and milk and may contain traces of nuts.
										<br />
										* Cross-Contamination: We take every precaution to minimize the risk of cross-contamination, but customers with nut allergies should exercise
										caution.
										<br />
										<br />
										We advise anyone with specific dietary concerns or allergies to contact us for more detailed information. Your health and well-being are our top
										priorities, and we are committed to ensuring a safe and enjoyable experience with our products.
										<br />
									</Typography>
								</AccordionDetails>
							</Accordion>
						</Stack>
					</Stack>
				</Grid>
			</Grid>
		</Box >
	) : (
		<>
			<h1>Loading...</h1>
		</>
	);
};

export default ProductInfo;

import PropTypes from 'prop-types';
import { forwardRef } from 'react';
// material
import { useTheme } from '@mui/material/styles';
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Typography,
} from '@mui/material';

const cardHeaderSX = {
  '& .MuiCardHeader-action': { mr: 0 },
};
const MainCard = forwardRef(
  (
    {
      divider = true,
      border = true,
      boxShadow,
      children,
      headerSX = { px: 3, py: { xs: 2, sm: 3 } },
      content = true,
      contentClass = '',
      contentSX = { p: { xs: 1.5, sm: 3 } },
      darkTitle,
      secondary,
      shadow,
      sx = {},
      title,
      ...others
    },
    ref
  ) => {
    const theme = useTheme();

    return (
      <Card
        ref={ref}
        {...others}
        sx={{
          border: border ? '1px solid' : 'none',
          borderColor: theme.palette.primary[200] + 75,
          ':hover': {
            boxShadow: boxShadow
              ? shadow || '0 2px 14px 0 rgb(32 40 45 / 8%)'
              : 'inherit',
          },
          ...sx,
        }}
      >
        {/* card header and action */}
        {title && (
          <>
            <Box sx={{ display: { xs: 'none', md: 'block' } }}>
              <CardHeader
                sx={{ ...cardHeaderSX, ...headerSX }}
                title={
                  <Typography variant={darkTitle ? 'h3' : null}>
                    {title}
                  </Typography>
                }
                action={secondary}
              />
            </Box>
            <Box sx={{ display: { xs: 'block', md: 'none' } }}>
              <CardHeader
                sx={{ ...cardHeaderSX, ...headerSX }}
                title={
                  <Typography variant={darkTitle ? 'h3' : null}>
                    {title}
                  </Typography>
                }
                subheader={secondary}
                subheaderTypographyProps={{ sx: { pt: 1.5 } }}
              />
            </Box>
          </>
        )}

        {/* content & header divider */}
        {title && divider && <Divider />}

        {/* card content */}
        {content && (
          <CardContent sx={contentSX} className={contentClass}>
            {children}
          </CardContent>
        )}
        {!content && children}
      </Card>
    );
  }
);

MainCard.propTypes = {
  border: PropTypes.bool,
  boxShadow: PropTypes.bool,
  children: PropTypes.node,
  content: PropTypes.bool,
  contentClass: PropTypes.string,
  contentSX: PropTypes.object,
  darkTitle: PropTypes.bool,
  secondary: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.string,
    PropTypes.object,
  ]),
  shadow: PropTypes.string,
  sx: PropTypes.object,
  title: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.string,
    PropTypes.object,
  ]),
};

export default MainCard;

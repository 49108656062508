import CryptoJS from "crypto-js";
import React, { createContext, useCallback, useContext, useEffect, useState } from "react";
// helpers
import { checkLoggedIn, getAuthPrivileges, getAuthUser } from "helpers/storage";
import PropTypes from "prop-types";
// config
import { LOCAL_STORAGE_KEY } from "config/constants";
import { forEach, isEmpty } from "lodash";
import { useSearchParams } from "react-router-dom";

const AppContext = createContext();
const AppProvider = ({ children }) => {
	// hooks
	const [searchParams, setSearchParams] = useSearchParams();
	// data
	const [menu, setMenu] = useState({ isOpen: [], opened: true });
	const [privileges, setPrivileges] = useState();
	const [currency, setCurrency] = useState({
		symbol: {
			krw: "₩",
			gbp: "£",
			usd: "$",
		},
		exchangeRate: {
			gbp2krw: 1700,
			usd2krw: 1250,
		},
	});

	const settingMenu = (type, value) => {
		if (type === "set_menu") {
			menu.opened = value;
		} else if (type === "menu_open") {
			menu.isOpen = [value];
		}
		setMenu(() => {
			return {
				...menu,
			};
		});
	};

	const settingPrivileges = () => {
		if (checkLoggedIn() && getAuthUser().level === "member") {
			const ciphertext = getAuthPrivileges();
			const bytes = CryptoJS.AES.decrypt(ciphertext, LOCAL_STORAGE_KEY);
			const decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
			const temp = [];
			forEach(decryptedData, (data) => temp.push(data.code));
			setPrivileges(temp);
		} else {
			setPrivileges();
		}
	};

	/**
	 * @param {Object} options - The options for updating the search parameters.
	 * @param {Array<{name: string, value: string, delData: boolean}>} options.data - The search parameters to update.
	 * @param {boolean} [options.replace=false] - Whether to replace the current history entry or create a new one.
	 * @param {boolean} [options.reload=true] - Whether to update the URL without triggering a re-render.
	 * @param {boolean} [options.setPage] - Whether to set the 'page' parameter. Number
	 * @param {boolean} [options.scrollY] - Whether to set the 'scrollY' parameter. Number
	 */
	const updateSearchParams = useCallback(
		({ data, replace = false, reload = true, setPage, forceRefresh = false, stateData }) => {
			// Create a new URLSearchParams instance
			const newSearchParams = new URLSearchParams(searchParams);
			// Loop through data
			if (!isEmpty(data)) {
				data.forEach((param) => {
					if (param?.delData) {
						newSearchParams.delete(param.name);
					} else {
						if (param?.name) {
							if (!param?.value) {
								newSearchParams.delete(param?.name);
							} else {
								newSearchParams.set(param?.name, param?.value || "");
							}
						}
					}
				});
			}
			// Set page if setPage is set, else delete page
			if (setPage === 0) newSearchParams.delete("page");
			else if (setPage) newSearchParams.set("page", setPage);
			// If value is same as previous value, return
			if (!forceRefresh && newSearchParams.toString() === searchParams.toString()) return;
			if (!reload && replace) {
				// Update URL & no Rerender & Replace
				window.history.replaceState(stateData ? stateData : null, "", "?" + newSearchParams.toString());
			} else if (!reload) {
				// Update URL & no Rerender & Push
				window.history.pushState(stateData ? stateData : null, "", "?" + newSearchParams.toString());
			} else {
				// Update URL & Rerender & Replace/Push
				setSearchParams(newSearchParams, { replace: replace, state: stateData });
			}
		},
		[searchParams, setSearchParams]
	);

	updateSearchParams.propTypes = {
		data: PropTypes.array,
		replace: PropTypes.bool,
		reload: PropTypes.bool,
		setPage: PropTypes.number,
		forceRefresh: PropTypes.bool,
		stateData: PropTypes.object,
	};

	useEffect(() => {
		settingPrivileges();
	}, []);

	return <AppContext.Provider value={{ menu, settingMenu, privileges, settingPrivileges, currency, setCurrency, updateSearchParams }}>{children}</AppContext.Provider>;
};
// make sure use
export const useGlobalContext = () => {
	return useContext(AppContext);
};

export { AppProvider };

import { useState, useEffect } from 'react';
import axios from 'axios';
import { path } from 'config/path';
import {
    Avatar, List, ListItem, ListItemAvatar, ListItemText,
    IconButton, Box, LinearProgress, Typography, Button,
    TextField, Grid, Select, MenuItem,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon  from '@mui/icons-material/Edit';
import CancelIcon from '@mui/icons-material/Cancel';
import { DeleteImage } from 'utils/util';

const imageType = [
    {type:0, name:'NotUsed'},
    {type:1, name:'DeskSlide'},
    {type:2, name:'MobileSlide'},
    {type:3, name:'Popup'},
];

function ImgUpload({ prevImages = [], storeId = 0, defImgType = 0, defNote = '', setUpdateImages = null }) {
    const [images, setImages] = useState([]);
    const [imgType, setImgType] = useState(defImgType);
    const [imgNote, setImgNote] = useState(defNote);
    const [imgParam, setImgParam] = useState(0);
    const [uploadProgress, setUploadProgress] = useState(0); 
    const [selectedIndex, setSelectedIndex] = useState(-1);

    useEffect(() => {
        console.log(prevImages);
        setImages(prevImages);
        setImgType(defImgType);
        setImgNote(defNote);
    }, [prevImages]);

    const handleFileChange = (e) => {
        const file = e.target.files[0];
        if (!file) return;

        const formData = new FormData();       
        formData.append('image', file);
        formData.append('type', imgType);
        formData.append('note', imgNote);
        formData.append('param', imgParam);
        formData.append('store_id', storeId);

        axios.post(`${path.ciHost}/index.php/api/v1/product/imgupload`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            },
            onUploadProgress: (progressEvent) => {
                const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                setUploadProgress(percentCompleted);
            }
        })
            .then(response => {
                if (response.data.filename) {
                    setImages(prevImages => [...prevImages, response.data]);
                    if (setUpdateImages) setUpdateImages([...images, response.data]);
                }
                setImgNote('');
                setImgParam(Number(imgParam) + 1);
                setUploadProgress(0); // 업로드 완료 후 진행률 초기화
            })
            .catch(error => {
                console.error('이미지 업로드 오류:', error);
                setUploadProgress(0); // 오류 발생 시 진행률 초기화
            });
    };

    const handleImgDelete = (id) => {
        DeleteImage(id);
        setImages((images) => images.filter((image, index) => image.id !== id));
        if (setUpdateImages) setUpdateImages(images.filter((image, index) => image.id !== id));
    };

    const handleListItemClick = (img) => {
        setSelectedIndex(img.id);
        setImgType(img.type);
        setImgParam(img.param);
        setImgNote(img.note);
        console.log(img.id);
    };

    const handleCancelSelect = () => {
        setSelectedIndex(-1);
    }

    const handleEdit = () => {
        console.log(selectedIndex);
        axios.post(`${path.ciHost}/index.php/api/v1/product/imgedit/${selectedIndex}`,
            { type:imgType, param:imgParam, note:imgNote }
        )
            .then(response => {
                if (response.data.result) {
                    let newImages = images;
                    newImages.forEach((img)=>{
                        if (img.id == selectedIndex) {
                            img.type = imgType;
                            img.param = imgParam;
                            img.note = imgNote;
                        }
                    })
                    setImages(newImages);
                    setSelectedIndex(-1);
                    if (setUpdateImages) setUpdateImages(newImages);
                }
            })
            .catch(error => {
                console.error('image edit error:', error);
            });
    }

    return (
        <Box>      
            <Box sx={{ flexGrow:1}}>   
                <Grid container spacing={1} >
                    { imgType<100 && (
                    <>
                        <Grid item xs={12} md={3}>
                            <Select
                                labelId="select-type"
                                id="imgType"
                                value={imgType}
                                label="Type"
                                onChange={(e) => {setImgType(e.target.value); setSelectedIndex(-1); }}
                                sx={{ width:'100%'}}
                            >
                                {imageType.map((itype, index) => (
                                    <MenuItem key={index} value={itype.type}>{itype.name}</MenuItem>
                                ))}
                            </Select>  
                        </Grid>  
                        <Grid item xs={12} md={1}>
                            <TextField fullWidth type='text' label='param' 
                                value={imgParam} onChange={(e) => setImgParam(e.target.value)}
                            />   
                        </Grid>
                        <Grid item xs={12} md={5}>
                            <TextField fullWidth type='text' label='note' 
                                value={imgNote} onChange={(e) => setImgNote(e.target.value)}
                            />   
                        </Grid>
                    </>
                    )}
                    { imgType==100 && (
                    <>
                        <Grid item xs={12} md={4}>
                            <TextField fullWidth type='text' label='param' 
                                value={imgParam} onChange={(e) => setImgParam(e.target.value)}
                            />   
                        </Grid>
                        <Grid item xs={12} md={5}>
                            <TextField fullWidth type='text' label='note' 
                                value={imgNote} onChange={(e) => setImgNote(e.target.value)}
                            />   
                        </Grid>
                    </>
                    )}

                    { (imgType<10000 || images.length == 0) && (
                    <Grid item xs={12} md={3}>
                        <Box display='flex' height='100%' sx={{ alignItems:'center', justifyContent:'center'}}>
                            { selectedIndex<0 ? ( 
                                imgType<10000 ? (
                                    <Button variant="contained" component="label">
                                        Image File
                                        <input
                                            type="file"
                                            accept="image/*"
                                            //capture="camera"
                                            hidden
                                            onChange={handleFileChange}
                                        />
                                    </Button>
                                ) : (
                                    <Box component="label" sx={{ cursor: 'pointer', display: 'inline-block' }}>
                                        <img
                                            src={`${path.basename}/images/fileupload.jpg`} //"/%PUBLIC_URL%/images/fileupload.jpg"
                                            alt="Upload"
                                            style={{ 
                                                width: '100%', 
                                                //height: '200px', 
                                                bjectFit: 'cover' 
                                            }}
                                        />
                                        <input
                                            type="file"
                                            accept="image/*"
                                            hidden
                                            onChange={handleFileChange}
                                        />
                                    </Box>
                                )
                            ):(
                            <Box>
                                <IconButton edge="end" aria-label="edit" onClick={() => handleEdit()}>
                                    <EditIcon />
                                </IconButton>
                                <IconButton edge="end" aria-label="cancel" onClick={() => handleCancelSelect()}>
                                    <CancelIcon />
                                </IconButton>
                            </Box>
                            )}
                        </Box>
                    </Grid>           
                    )}         
                </Grid>
            </Box>
            {uploadProgress > 0 && (
                <Box sx={{ width: '100%', mb: 2 }}>
                    <LinearProgress variant="determinate" value={uploadProgress} />
                    <Typography variant="body2" color="textSecondary">{uploadProgress}%</Typography>
                </Box>
            )}   
             
            <Box>         
                <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
                    {[...images].reverse().filter(item=>item.type==imgType).map((image, index) => (
                        <ListItem key={index}
                            secondaryAction={
                                <IconButton edge="end" aria-label="delete" onClick={() => handleImgDelete(image.id)}>
                                    <DeleteIcon />
                                </IconButton>
                            }
                            sx={{ backgroundColor : selectedIndex === image.id ? 'lightcyan' : 'inherit' }}
                        >
                            <ListItemAvatar onClick={(event) => handleListItemClick(image)}>
                                <Avatar variant="rounded" sx={{ width:'2.5em', height:'2.5em'}}>
                                    <img src={`${path.ciHost}/uploads/${storeId}/` + image.filename} alt={image.filename} style={{ maxWidth: '100%' }} />
                                </Avatar>
                            </ListItemAvatar>
                            <ListItemText
                                onClick={(event) => handleListItemClick(image)}
                                secondary={image.type<10000 ? image.note : null}
                                primary={image.type<100 ? (imageType[image.type].name+' : '+image.param) : 
                                    ( image.type<10000 ? image.param : image.filename)
                                }
                                sx={{ pl:1 }}
                            />
                        </ListItem>
                    ))}
                </List>
            </Box>  
        
        </Box>
    );
}

export default ImgUpload;
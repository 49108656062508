import { useEffect, useState } from 'react';
import { forEach } from 'lodash';
import { isMobile } from 'react-device-detect';
// material
import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
} from '@mui/material';
// graphql
import { useQuery } from '@apollo/client';
import { INVENTORY_INOUT_MEMBERS_QUERY } from 'graphql/query';

const InOutMember = ({
  value,
  required = false,
  requiredMark = '',
  message = '',
  allitem = false,
  handleChange,
}) => {
  // data
  const [member, setMember] = useState(allitem ? 'all-items' : '');
  const [members, setMembers] = useState();
  // graphql
  const { loading, error } = useQuery(INVENTORY_INOUT_MEMBERS_QUERY, {
    onCompleted: (data) => {
      if (data.inventoryinoutmembers.response.status) {
        const items = [];
        forEach(data.inventoryinoutmembers.items, (member) => {
          items.push({
            value: member.member_name.toLowerCase(),
            label: member.member_name.toUpperCase(),
          });
        });
        setMembers(items);
      }
    },
  });

  useEffect(() => {
    if (value || value === '') setMember(value);
  }, [value]);

  if (loading) return <>Loading...</>;
  if (error) return <>ERROR...</>;

  return (
    <FormControl
      variant={isMobile ? 'standard' : 'outlined'}
      sx={{ minWidth: 120 }}
      error={required}
    >
      <InputLabel>MEMBER {requiredMark}</InputLabel>
      {members && (
        <Select
          label={`Member ${requiredMark}`}
          autoWidth
          value={member}
          onChange={handleChange}
        >
          {allitem && <MenuItem value='all-items'>ALL MEMBER</MenuItem>}
          {members.map((member, index) => (
            <MenuItem value={member.value} key={index}>
              {member.label}
            </MenuItem>
          ))}
        </Select>
      )}
      <FormHelperText>{message}</FormHelperText>
    </FormControl>
  );
};

export default InOutMember;

import { lazy } from 'react';
import { Navigate } from 'react-router-dom';
// project imports
import Loadable from 'components/ui/Loadable';
import MinimalLayout from 'layout/MinimalLayout';
// helper
import { checkLoggedIn, checkAuthLevel } from 'helpers/storage';
// config
import { path } from 'config/path';

const Login = Loadable(lazy(() => import('pages/admin/Login')));
const AdminStore = Loadable(lazy(() => import('pages/admin/Store')));
const AdminStores = Loadable(lazy(() => import('pages/admin/Stores')));

const PrivateRoute = ({ component: Component }) => {
  if (checkLoggedIn() && checkAuthLevel() === 'admin') {
    return <Component />;
  }
  return <Navigate to={path.admin.login} />;
};

const Admin = {
  path: path.admin.default,
  element: <MinimalLayout />,
  children: [
    {
      path: path.admin.login,
      element: <Login />,
    },
    {
      path: path.admin.setting,
      element: <PrivateRoute component={AdminStore} />,
    },
    {
      path: path.admin.list,
      element: <PrivateRoute component={AdminStores} />,
    },
  ],
};

export default Admin;

import { 
	Box, Divider, Grid, Stack, Typography, 
	Accordion, AccordionDetails, AccordionSummary 
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useState, useEffect, useContext } from "react";
import { path } from 'config/path';
import { StoreContext } from "layout/HomeLayout";
import ImageBox from "components/ui/ImageBox";
import { ExpandMore } from "@mui/icons-material";

// MENU
const MenuMCakeCategoryGrid = ({ name, category, bHead }) => {
	// HOOKS
	const navigate = useNavigate();
	const { store } = useContext(StoreContext);
	const [menu, setMenu] = useState({main:{}, subcat:[]});
	// FUNCTIONS
	const handleClick = (cat) => {
		navigate(`/menu/${cat}`);
	};

	useEffect(() => {
		let cat = {main:{name:name}, subcat:[]};
		category.forEach((values, keys) => {			
			let prod = category.get(keys);
			//console.log(prod);
			if (prod.length>0 && prod[0].subcategory=='') {
				cat.main = prod[0];
			} 
			else {
				cat.subcat.push({
					title: keys,
					category: keys,
					image: prod[0].image ? `${path.imageServer}${path.imageDirectory}/${store.key}/${prod[0].image}` : `${path.basename}/images/icon-product.svg`
				}); 
			}
		});
		//console.log(cat);
		setMenu(cat);
	}, [category]);
	// RENDER
	return (
		<Box sx={{ mb: 4, px: 2 }}>
			{ bHead && <Box display='block' width='100%'>
				<ImageBox imageUrl={`${path.imageServer}${path.imageDirectory}/${store.key}/${menu.main.image}`} isBg={true} opc='70%'>
					<Box width='100%' height='100%' sx={{textAlign:'left'}}>
						<Typography variant="h1" sx={{
							//textTransform:'uppercase', 
							fontSize:'4em',
							color:'#777',
							//textShadow:'-1px -1px 0 #444, 1px -1px 0 #444, -1px 1px 0 #444,1px 1px 0 #444;'
						}}>
							{menu.main.name}
						</Typography>
					</Box>
					{ menu.main.name1 && ( menu.main.note ? (
						<Accordion disableGutters square defaultExpanded sx={{ backgroundColor:'inherit'}}>
							<AccordionSummary expandIcon={<ExpandMore />} sx={{p:1, minHeight:0 }}>
								<Typography variant="h4">{menu.main.name1}</Typography>
							</AccordionSummary>
							<AccordionDetails sx={{p:1}}>
								<Typography sx={{ textAlign: "justify" }} variant="body1">
									{menu.main.note}
								</Typography>
							</AccordionDetails>
						</Accordion>
					) : (
						<Box width='100%' sx={{textAlign:'left'}}>
							<Typography variant="h4">{menu.main.name1}</Typography>
						</Box>
					) ) } 
				</ImageBox>
			</Box>}
			<Divider sx={{ borderBottomWidth: 2, mt: 0 }} />
			<Grid container spacing={2} sx={{ mt: 0 }}>
				{menu.subcat.map((submenu, index) => {
					const key = `${submenu.category}_${index}`;
					if (submenu.title.indexOf("topper")>=0) {
						return null;
					}
					return (
						<Grid key={key} item xs={6} md={4} onClick={() => handleClick(submenu.category)}>
							<Stack alignItems="center" spacing={1}>
								<Box display='flex' width='100%' sx={{ 
									flexDirection:'column',
								}}>
									<ImageBox imageUrl={submenu.image} isBg={true} br='1em'>
										<Box display='flex' height='20em' sx={{
											alignItems:'flex-start',
											justifyContent:'center',
										}}>
											<Typography variant="h1" sx={{ 
												textTransform: 'uppercase',
												fontSize:'2em',
												color:'white',
												//textShadow:'-1px -1px 0 #888, 1px -1px 0 #888, -1px 1px 0 #888,1px 1px 0 #888;'
											}}>
												{/* {submenu.title} */}
											</Typography>
										</Box>
									</ImageBox>
								</Box>
							</Stack>
						</Grid>
					);
				})}
			</Grid>
		</Box>
	);
};

export default MenuMCakeCategoryGrid;

import { includes } from 'lodash';
// context
import { useGlobalContext } from 'context';
// components
import { MainCard } from 'components/ui/cards';
import { Typography } from '@mui/material';

const Privilege = ({ code, isEmpty = false, children }) => {
  const { privileges } = useGlobalContext();
  if (privileges) {
    if (includes(privileges, code)) {
      return <>{children}</>;
    }
    if (isEmpty) {
      return <></>;
    }
    return (
      <MainCard contentSX={{ my: 20 }}>
        <Typography variant='h2' color={'error'} align='center'>
          You do not have permission for this page.
        </Typography>
      </MainCard>
    );
  }
  return <>{children}</>;
};

export default Privilege;

import { useEffect, useState } from 'react';
// material
import { Button, Stack, TextField } from '@mui/material';
// icon
import { IconPhoto } from '@tabler/icons';
// components
import { ImageView } from '.';
// helper
import {
  checkImageTypeAndAlert,
  checkImageSize,
  previewImageURL,
  resizeImage,
} from 'helpers/image';

const ImageUpload = ({
  image,
  type = 'menu',
  lebel = 'image',
  imageId = 'image',
  handleImage,
}) => {
  // data
  const [imageData, setImageData] = useState();
  const [size, setSize] = useState({ width: 350, height: 350 });

  const handleChangeImage = (event) => {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      if (!checkImageTypeAndAlert(file.type)) {
        return;
      }
      if (checkImageSize(file.size)) {
        handleImage(file);
        previewImageURL(file, (result) => {
          setImageData({ name: file.name, src: result });
        });
      } else {
        resizeImage(
          file,
          type,
          (imageFile) => {
            handleImage(imageFile);
            previewImageURL(imageFile, (result) => {
              setImageData({ name: imageFile.name, src: result });
            });
          },
          'file'
        );
      }
    }
  };

  useEffect(() => {
    if (type === 'main') {
      // 1920:1080 (16:9) => 700:394, 600:338, 500:218
      setSize({ width: 600, height: 338 });
    }
  }, [type]);

  useEffect(() => {
    if (image || image === null) {
      setImageData(image);
    }
  }, [image]);

  return (
    <>
      {imageData && imageData.name && (
        <ImageView width={size.width} height={size.height} image={imageData} />
      )}
      <Stack direction={{ xs: 'column', sm: 'row' }} spacing={1}>
        <TextField
          fullWidth
          autoComplete='image'
          type='text'
          label={lebel}
          value={imageData?.name || ''}
          InputProps={{ readOnly: true }}
        />
        <label htmlFor={imageId}>
          <input
            accept='image/*'
            id={imageId}
            type='file'
            style={{ display: 'none' }}
            onChange={handleChangeImage}
          />
          <Button
            variant='contained'
            component='span'
            sx={{ width: { xs: '100%', sm: 150 }, height: '100%' }}
            startIcon={<IconPhoto />}
          >
            Image File
          </Button>
        </label>
      </Stack>
    </>
  );
};

export default ImageUpload;

import { gql } from "@apollo/client";

export const INVENTORY_QUERY = gql`
	query GetInventory($storeId: ID!, $itemId: ID!) {
		currentStoreId @client @export(as: "storeId")
		inventory(store_id: $storeId, item_id: $itemId) {
			response {
				status
				code
				message
			}
			item {
				id
				brand
				category
				subcategory
				storage
				name
				name1
				code
				product_code
				model
				price
				price_decl
				discount_price
				sale_price
				other_price
				delivery
				shipping
				VAT1
				VAT2
				color
				size
				qty
				ord_qty
				sort
				weight
				quantities {
					id
					color
					size
					qty
				}
				image
				site_url
				buy_url
				channel
				note
				created
			}
		}
	}
`;

export const INVENTORIES_QUERY = gql`
	query GetInventories(
		$storeId: ID!
		$brandId: ID
		$categoryId: ID
		$limit: Int
		$offset: Int
		$category: String
		$subcategory: String
		$brand: String
		$search: String
		$sort: String
		$soldOut: String
	) {
		currentStoreId @client @export(as: "storeId")
		inventories(
			store_id: $storeId
			brand_id: $brandId
			category_id: $categoryId
			limit: $limit
			offset: $offset
			category: $category
			subcategory: $subcategory
			brand: $brand
			search: $search
			sort: $sort
			sold_out: $soldOut
		) {
			response {
				status
				code
				message
			}
			items {
				id
				name
				name1
				model
				brand
				category
				subcategory
				price
				price_decl
				discount_price
				sale_price
				other_price
				delivery
				shipping
				VAT1
				VAT2
				color
				size
				qty
				ord_qty
				weight
				sort
				storage
				image
				note
				site_url
				buy_url
				channel
			}
			total {
				price
				sale_price
			}
			pagination {
				totalRow
				totalPage
			}
		}
	}
`;

export const INVENTORY_INOUTS_QUERY = gql`
	query GetInventoryInOuts($storeId: ID!, $itemId: ID, $limit: Int!, $offset: Int!, $search: String) {
		currentStoreId @client @export(as: "storeId")
		inventoryinouts(store_id: $storeId, item_id: $itemId, limit: $limit, offset: $offset, search: $search) {
			response {
				status
				code
				message
			}
			items {
				id
				product_id
				brand
				category
				name
				member_name
				model
				price
				qty
				ord_qty
				shipping
				date
				note
				updated
				created
			}
			pagination {
				totalRow
				totalPage
			}
		}
	}
`;

export const INVENTORY_INOUT_MEMBERS_QUERY = gql`
	query GetInventoryInOutMembers($storeId: ID!) {
		currentStoreId @client @export(as: "storeId")
		inventoryinoutmembers(store_id: $storeId) {
			response {
				status
				message
			}
			items {
				member_id
				member_name
				member_type
			}
		}
	}
`;

export const INVENTORY_WAREHOUSES_QUERY = gql`
	query GetInventoryInOutList($storeId: ID!, $limit: Int!, $offset: Int!, $brand: String, $io: String, $member: String, $datetype: String!, $start: String, $end: String, $search: String) {
		currentStoreId @client @export(as: "storeId")
		inventorywarehouses(store_id: $storeId, limit: $limit, offset: $offset, brand: $brand, io: $io, member: $member, datetype: $datetype, start: $start, end: $end, search: $search) {
			response {
				status
				code
				message
			}
			items {
				id
				product_id
				brand
				category
				storage
				name
				model
				price
				shipping
				color
				size
				qty
				ord_qty
				date
				member_name
				note
				updated
				created
			}
			pagination {
				totalRow
				totalPage
			}
		}
	}
`;

import { lazy } from 'react';
import { Navigate } from 'react-router-dom';
// config
import { path } from 'config/path';
// project imports
import MainLayout from 'layout/MainLayout';
import Loadable from 'components/ui/Loadable';
import PrivateRoute from './PrivateRoute';

// dashboard routing
const Dashboard = Loadable(lazy(() => import('pages/dashboard')));

// inventory routing
const InventoryItems = Loadable(lazy(() => import('pages/inventory/Items')));
const InventoryItemInfo = Loadable(
  lazy(() => import('pages/inventory/ItemInfo'))
);
const InventoryItemInOut = Loadable(
  lazy(() => import('pages/inventory/ItemInOut'))
);
const InventoryWarehouse = Loadable(
  lazy(() => import('pages/inventory/Warehouse'))
);
const InventoryOrder = Loadable(lazy(() => import('pages/inventory/ItemOrder')));

const Main = {
  path: path.urls.default,
  element: <MainLayout />,
  children: [
    {
      path: path.urls.inventory,
      element: <PrivateRoute component={InventoryOrder} />,
    },
    {
      path: path.urls.dashboard,
      element: <PrivateRoute component={Dashboard} />,
    },
    {
      path: path.urls.inventoryItems,
      element: <PrivateRoute component={InventoryItems} />,
    },
    {
      path: path.urls.inventoryItemInOut,
      element: <PrivateRoute component={InventoryItemInOut} />,
    },
    {
      path: path.urls.inventoryItemInfo,
      element: <PrivateRoute component={InventoryItemInfo} />,
    },
    {
      path: `${path.urls.inventoryItemInfo}/:id`,
      element: <PrivateRoute component={InventoryItemInfo} />,
    },
    {
      path: path.urls.inventoryWarehouse,
      element: <PrivateRoute component={InventoryWarehouse} />,
    },
    {
      path: path.urls.inventoryOrders,
      element: <PrivateRoute component={InventoryOrder} />,
    },
  ],
};

export default Main;

// icon
import { IconSettings } from '@tabler/icons';

const admin = {
  title: 'ADMIN',
  type: 'group',
  children: [
    {
      id: 'setting',
      title: 'Setting',
      type: 'collapse',
      icon: IconSettings,
      children: [
        {
          id: 'setting-store',
          title: 'Store',
          type: 'item',
          url: '/_admin/setting/store',
        },
      ],
    },
  ],
};

export default admin;

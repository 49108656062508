// material
import { Box } from '@mui/material';
// helpers
import { getAuthStore } from 'helpers/storage';

const Logo = () => {
  return (
    <Box
      sx={{ fontSize: 22,
        fontWeight: 700,
        //mt: 1,
        textTransform: 'uppercase',
        lineHeight:'normal'
      }}
    >
      {getAuthStore().name}
    </Box>
  );
};

export default Logo;

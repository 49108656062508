import { lazy } from 'react';
// config
import { path } from 'config/path';
// project imports
import Loadable from 'components/ui/Loadable';
import MinimalLayout from 'layout/MinimalLayout';

const QrcodeItem = Loadable(lazy(() => import('pages/qrcode/Item')));

const Qrcode = {
  path: path.urls.default,
  element: <MinimalLayout />,
  children: [
    {
      path: `${path.urls.qrcodeItem}/:id`,
      element: <QrcodeItem />,
    },
  ],
};

export default Qrcode;

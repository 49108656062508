// icon
import InventoryIcon from '@mui/icons-material/Inventory';
// config
import { path } from 'config/path';

const menu = {
  // title: 'Inventory',
  type: 'group',
  level: 'client',
  children: [
    {
      id: 'items',
      title: 'Products',
      // type: 'collapse',
      type: 'item',
      url: path.urls.inventoryItems,
      icon: InventoryIcon,
      breadcrumbs: true,
      // children: [
      //   {
      //     id: 'orders',
      //     title: 'Orders',
      //     type: 'item',
      //     url: path.urls.inventoryOrders,
      //   },
      //   {
      //     id: 'items',
      //     title: 'Menu',
      //     type: 'item',
      //     url: path.urls.inventoryItems,
      //   },
        
      //   // {
      //   //   id: 'warehouse',
      //   //   title: 'In/Out',
      //   //   type: 'item',
      //   //   url: path.urls.inventoryWarehouse,
      //   // },
      //   // {
      //   //   id: 'outgoing',
      //   //   title: 'QrScan',
      //   //   type: 'item',
      //   //   url: path.urls.inventoryItemInOut,
      //   // },
      // ],
    },
  ],
};

export default menu;

// material
import { styled } from '@mui/material/styles';
// helper
import { getAuthStore } from 'helpers/storage';
// config
import { path } from 'config/path';

const ImageBox = styled('div')({
  position: 'relative',
  '& img': {
    position: 'absolute',
    top: 0,
    left: 0,
    transform: 'translate(50, 50)',
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    margin: 'auto',
  },
});
const ImageView = ({ image, width = 350, height = 350 }) => {
  const src =
    image.src ||
    `${path.imageServer}${path.imageDirectory}/${getAuthStore().key}/${
      image.name
    }`;
  return (
    <ImageBox sx={{ width: { sx: '100%', sm: width }, height }}>
      <img src={src} alt={image.name} />
    </ImageBox>
  );
};

export default ImageView;

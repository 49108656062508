import { Box } from '@mui/material';

const ImageBox = ({ children, imageUrl, isBg, opc = 0, br = 0}) => {
  //const opc = 0.5;
  return isBg ? (
    <Box sx={{
      display:'flex',
      flexDirection:'column',
      justifyContent:'center',
      alignItems:'center',
      p : '1em',
      backgroundImage: `url(${imageUrl})`,
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      backgroundRepeat: 'no-repeat',
      borderRadius:br,
      backgroundBlendMode:'color',
      //backgroundColor:'#dddddd',
      backgroundColor: `rgba(255, 255, 255, ${opc})`
    }}>      
      { children }
    </Box>
  ) : (
  <Box
    sx={{
      position: 'relative',
      width: '100%',
      height: 'auto',
      display: 'inline-block',
    }}
  >
    <Box
      component="img"
      src={imageUrl}
      alt="background"
      sx={{
        width: '100%',
        height: 'auto',
        display: 'block',
      }}
    /> 
    <Box
      sx={{
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        backgroundColor: `rgba(255, 255, 255, ${opc})`, // Optional: semi-transparent background for overlay
        color: 'white', // Text color
        textAlign: 'center', // Center text alignment
      }}
    >
      { children }
    </Box>
  </Box>
  )
};

export default ImageBox;

import CheckoutForm from "components/page/menu/checkoutForm";
import CheckoutSummary from "components/page/menu/checkoutSummary";
import { useLocation } from "react-router-dom";

// CHECKOUT PAGE
const Checkout = () => {
	// HOOKS
	const location = useLocation();
	const orderReference = location.state?.orderReference;

	if (!orderReference) {
		return <CheckoutForm />;
	} else {
		return <CheckoutSummary orderReference={orderReference} />;
	}
};

export default Checkout;
import { useTheme } from '@mui/material/styles';
import { Box, Grid, Typography, Button, useMediaQuery } from "@mui/material"
import ImageBox from "components/ui/ImageBox";
import { path } from 'config/path';

const About = () => {
  const theme = useTheme();
  const matchesXs = useMediaQuery(theme.breakpoints.down('md'));
  return (
    <Box display='block' sx={{ textAlign:'center'}}>
      <Grid container spacing={1}>
        <Grid item xs={12} md={8}>
          <Box sx={{ p:'1em', mt:{xs:'3em', md:'5em'} }}>
            <Box
              component='img' 
              src={`${path.basename}/images/choice/OWNER.webp`} alt="OWNER" 
              sx={{ 
                float:'left', marginRight:'1em', marginBottom:'1em', 
                borderRadius:'50%', overflow:'hidden',
                width:{ xs:'50%', md:'30%'}
              }} 
            />
            <Box sx={{ px:{ xs:0, md:'2em' } }}>
              <Typography variant="h5" sx={{
                fontSize:{xs:'1.8em', md:'2em'}, 
                color:'darkorange', lineHeight:'1.5em', pb:'2em'
              }}>
                Mrs Hyelin CHOI<br/>
                CHOI'CE CAKE & COFFEE
              </Typography>
              <Typography variant="body1" sx={{
                lineHeight:'1.8em', 
                fontSize:{xs:'1.6em', md:'2em'}, 
                textAlign:'right', 
              }}>
                Mrs Hyelin Choi's mission in setting up CHOI'CE was to spread her love and passion of cake making to everyone - catering for the needs of those who prefer a different dietary content but the same great taste. This led to the fusion and production of Korean-style cakes. Mrs Choi believes we have the perfect range of cakes that will treat your special day.
              </Typography>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} md={4}>
          <Box sx={{ p:'1em'}}>
            <ImageBox imageUrl={`${path.basename}/images/choice/cake_about.webp`} isBg={false}>
            <Box width='auto' height='100%' display='flex' sx={{
              flexDirection:'column',
              justifyContent:'flex-end',
              py:'1em'
              }}
            >
              <Button 
                href="./menu" 
                variant='contained' 
                color='secondary'
                sx={{ mb:'1em', width : 'auto'}} 
              >
                Book your cake
              </Button>
            </Box>
            </ImageBox>
          </Box>
        </Grid>
      </Grid>
      <Box>
        <ImageBox imageUrl={`${path.basename}/images/choice/macarongbg.webp`} isBg={true} opc={0}>
          <Box width='auto' height='100%' display='flex' sx={{
            flexDirection:'column',
            justifyContent:'flex-end',
            py:'1em',
            backgroundColor: `rgba(255, 255, 255, 0.5)`
            }}>          
            <Typography variant='h2'>
              @choicecakencoffee
            </Typography>
            <Typography variant='h3' sx={{ p:'1em' }}>
              Please see the most recent update through Instagram.
            </Typography>
            <Box>
              <Button 
                href="https://www.instagram.com/choicecakencoffee_cafe/" 
                target='_blank'
                variant='contained' 
                color='secondary'
                sx={{ width : 'auto'}} 
              >
                Following Us
              </Button>
            </Box>
          </Box>
        </ImageBox>
      </Box>
    </Box>
  );
};

export default About;
import * as React from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MobileStepper from '@mui/material/MobileStepper';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import SwipeableViews from 'react-swipeable-views';
import { autoPlay } from 'react-swipeable-views-utils';
import StarIcon from '@mui/icons-material/Star';

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);
const MAX_LENGTH = 350;
const reviews = [
  {
    customer: 'Rachel Chew',
    comment: "This is our favourite cake spot in London! What we love about Cho'ice is that the cakes are the freshest and the taste just nice - not overly sweet like others on the market. This is the always our first choice for ordering whole cakes for birthdays and occasions and have been taking our friends and family to this lovely establishment for tea",
  },
  {
    customer: 'Samuel Loman',
    comment:"Lovely cake, fast efficient service, received to celebrate an event and bought multiple cakes from them and been extremely happy everything. Would recommend",
  },
  {
    customer: 'Michael Chang',
    comment:"Tried the Strawberry Cream and Chocolate Ganache slices yesterday and they were delightful! The Strawberry Cream was perfectly sweet and refreshingly light, while the Chocolate Ganache was rich and decadently smooth. Highly recommend for anyone looking for a delicious treat!",
  },
  {
    customer: 'Kevan Laybourn',
    comment:"Best coffee shop I’ve been in for as long as I can remember. Lovely friendly service. INCREDIBLE cake - when I first looked at my slice of mocha cake I was worried it would be too heavy and too sweet. However it was light, fluffy and not too sweet at all - just perfect. Really great coffee too. A really pleasant atmosphere; quiet and relaxing with delightfully comfortable chairs! Sadly I’m not local, but next time I’m in the area I’ll definitely come back. Highly recommended.",
  },
  {
    customer: 'Tiffany Choi',
    comment:"I have ordered their birthday cakes for multiple times. Never disappoint me, they taste delicious with fresh ingredient and not overly sweet. The service of the staff is excellent too.",
  },
];

const CustomerReview = ({step}) => {
  return (
    <Box
      sx={{
        position: 'relative',
        width: '100%',
        height: '25em', 
        display: 'inline-block',
        overflow:'hidden',
      }}
    >
      <Box
        sx={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          flexDirection: 'column',
          backgroundColor: 'rgba(0, 0, 0, 0.5)', // Optional: semi-transparent background for overlay
          color: 'white', // Text color
          textAlign: 'center', // Center text alignment
        }}
      >
        <Box display='block'>
          <Typography variant='h2' sx={{ 
            fontSize:'2em',
            color:'white',
            pt:'1em',
            }}>
              {step.customer}
          </Typography>
          <Box>
            <StarIcon sx={{ color : 'khaki' }}/>
            <StarIcon sx={{ color : 'khaki' }}/>
            <StarIcon sx={{ color : 'khaki' }}/>
            <StarIcon sx={{ color : 'khaki' }}/>
            <StarIcon sx={{ color : 'khaki' }}/>
          </Box>
          <Box sx={{ height : '11em'}}>
            <Typography variant='body1' sx={{ 
              mt:'1em',
              px:'2em', 
              fontSize:'1.2em',
              fontStyle:'italic',
            }}>
                {step.comment.length>MAX_LENGTH ? step.comment.substring(0,MAX_LENGTH)+'...' : step.comment }
            </Typography>
          </Box>
        </Box>
        <Button 
          href="https://g.page/r/CUVHdvjkBKjlEB0/review" 
          target='_blank'
          variant='contained' 
          sx={{ mb:'1em', backgroundColor:'white', color:'black'}} 
        >
          Leave Your Review
        </Button>
      </Box>
    </Box>
  )
}

function Review() {
  const theme = useTheme();
  const [activeStep, setActiveStep] = React.useState(0);
  const maxSteps = reviews.length;

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStepChange = (step) => {
    setActiveStep(step);
  };

  return (
    <Box sx={{ 
      pt : {xs:0, md:'3em'},
      pl : {xs:0, md:'3em'} 
    }}>
      <AutoPlaySwipeableViews
        interval={8000}
        axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
        index={activeStep}
        onChangeIndex={handleStepChange}
        enableMouseEvents
      >
        {reviews.map((step, index) => (
          <div key={index}>
            {Math.abs(activeStep - index) <= 2 ? (
              <CustomerReview step={step} />
            ) : null}
          </div>
        ))}
      </AutoPlaySwipeableViews>
      {/* <MobileStepper
        steps={maxSteps}
        position="static"
        activeStep={activeStep}
        nextButton={
          <Button
            size="small"
            onClick={handleNext}
            disabled={activeStep === maxSteps - 1}
          >
            Next
            {theme.direction === 'rtl' ? (
              <KeyboardArrowLeft />
            ) : (
              <KeyboardArrowRight />
            )}
          </Button>
        }
        backButton={
          <Button size="small" onClick={handleBack} disabled={activeStep === 0}>
            {theme.direction === 'rtl' ? (
              <KeyboardArrowRight />
            ) : (
              <KeyboardArrowLeft />
            )}
            Back
          </Button>
        }
      /> */}
    </Box>
  );
}

export default Review;

import { gql } from "@apollo/client";

export const DASHBOARD_DATA_QUERY = gql`
	query GetDashboardData($storeId: ID!, $start: String!, $end: String!) {
		currentStoreId @client @export(as: "storeId")
		dashboard(store_id: $storeId, start: $start, end: $end) {
			response {
				status
				code
				message
			}
            data {
				out {
					sum
					count
				}
				in {
					sum
					count
				}
				order {
					sum
					count
				}
				total_stock
				top_products {
					id
					name
					avg_price
					qty
					model
				}
			}
		}
	}
`;

export const DASHBOARD_GRAPH_DATA_QUERY = gql`
	query GetDashboardGraphData($storeId: ID!, $start: String!, $end: String!) {
		currentStoreId @client @export(as: "storeId")
		dashboardGraph(store_id: $storeId, start: $start, end: $end) {
			response {
				status
				code
				message
			}
			data {
				out
				in
				order
			}
		}
	}
`;
import { gql } from '@apollo/client';

export const MEMBERS_QUERY = gql`
  query GetMembers($storeId: ID!) {
    currentStoreId @client @export(as: "storeId")
    members(store_id: $storeId) {
      response {
        status
        message
      }
      items {
        id
        name
        contact
        privileges
        access_login
        access_ip
      }
    }
  }
`;

export const MEMBER_QUERY = gql`
  query GetMembers($storeId: ID!, $memberId: ID!) {
    currentStoreId @client @export(as: "storeId")
    member(store_id: $storeId, member_id: $memberId) {
      response {
        status
        message
      }
      item {
        id
        name
        member_id
        contact
        privileges
        access_login
        access_ip
      }
    }
  }
`;

import { gql } from '@apollo/client';

export const STORAGES_QUERY = gql`
  query GetStorages($storeId: ID!) {
    currentStoreId @client @export(as: "storeId")
    storages(store_id: $storeId) {
      response {
        status
        code
        message
      }
      items {
        storage
      }
    }
  }
`;

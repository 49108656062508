import { useEffect, useState } from 'react';
import { forEach } from 'lodash';
import { isMobile } from 'react-device-detect';
// material
import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  Stack,
} from '@mui/material';
// graphql
import { useLazyQuery, useQuery } from '@apollo/client';
import { CATEGORIES_QUERY, SUBCATEGORIES_QUERY } from 'graphql/query';

const Category = ({
  value,
  subvalue,
  required = false,
  requiredMark = '',
  message = '',
  allitem = false,
  withSubcaterory = false,
  handleChange,
  handleChangeSubcategory,
}) => {
  // data
  const [category, setCategory] = useState(allitem ? 'all-items' : '');
  const [categories, setCategories] = useState();
  const [subcategory, setSubcategory] = useState(allitem ? 'all-items' : '');
  const [subcategories, setSubcategories] = useState();
  // graphql
  const [getSubcategories] = useLazyQuery(SUBCATEGORIES_QUERY, {
    notifyOnNetworkStatusChange : true,
    onCompleted: (data) => {
      if (data.subcategories.response.status) {
        const temp = {};
        forEach(data.subcategories.items, (subcategory) => {
          if (!temp[subcategory.category]) temp[subcategory.category] = [];
          temp[subcategory.category].push({
            value: subcategory.subcategory.toLowerCase(),
            label: subcategory.subcategory.toUpperCase(),
          });
        });
        setSubcategories(temp);
      }
    },
  });
  const { loading, error } = useQuery(CATEGORIES_QUERY, {
    onCompleted: (data) => {
      if (data.categories.response.status) {
        const items = [];
        const temp = [];
        forEach(data.categories.items, (category) => {
          if (category.category) {
            items.push({
              value: category.category.toLowerCase(),
              label: category.category.toUpperCase(),
            });
            if (withSubcaterory) temp.push(category.category);
          }
        });
        setCategories(items);
        if (withSubcaterory) {
          getSubcategories({ variables: { categories: JSON.stringify(temp) } });
        }
      }
    },
  });

  useEffect(() => {
    if (value || value === '') {
      if (value === 'all-items') {
        setSubcategory(allitem ? 'all-items' : '');
      }
      setCategory(value);
    }
    // eslint-disable-next-line
  }, [value]);

  useEffect(() => {
    if (subvalue || subvalue === '') setSubcategory(subvalue);
  }, [subvalue]);

  if (loading) return <>Loading...</>;
  if (error) return <>ERROR...</>;

  return (
    <Stack direction={{ xs: 'column', sm: 'row' }} spacing={1}>
      <FormControl
        variant={isMobile ? 'standard' : 'outlined'}
        sx={{ minWidth: 120 }}
        error={required}
      >
        <InputLabel>CATEGORY {requiredMark}</InputLabel>
        {categories && (
          <Select
            label='Select-Category'
            value={category}
            onChange={handleChange}
          >
            {allitem && <MenuItem value='all-items'>ALL CATEGORY</MenuItem>}
            {categories.map((category, index) => (
              <MenuItem value={category.value} key={index}>
                {category.label}
              </MenuItem>
            ))}
          </Select>
        )}
        <FormHelperText>{message}</FormHelperText>
      </FormControl>
      {withSubcaterory && (
        <FormControl
          variant={isMobile ? 'standard' : 'outlined'}
          sx={{ minWidth: 150 }}
          error={required}
        >
          <InputLabel>SUB-CATEGORY {requiredMark}</InputLabel>
          {subcategories && (
            <Select
              label='Select-Subcategory'
              value={subcategory}
              onChange={handleChangeSubcategory}
            >
              {allitem && (
                <MenuItem value='all-items'>ALL SUB-CATEGORY</MenuItem>
              )}
              {subcategories[category] &&
                subcategories[category].map((subcategory, index) => (
                  <MenuItem value={subcategory.value} key={index}>
                    {subcategory.label}
                  </MenuItem>
                ))}
            </Select>
          )}
          <FormHelperText>{message}</FormHelperText>
        </FormControl>
      )}
    </Stack>
  );
};

export default Category;

// Material UI components
import { Button, FormControl, InputLabel, MenuItem, Popover, Select, Stack, TextField } from "@mui/material";
import { KeyboardArrowDown } from "@mui/icons-material";
// Date pickers
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
// Custom components
import { DateRange } from "components/app";
// Context
import { useGlobalContext } from "context";
// Date utilities
import { endOfMonth, format, isValid, startOfMonth, startOfWeek, startOfYear } from "date-fns";
// React hooks
import { useCallback, useEffect, useMemo, useState } from "react";
// Router
import { useSearchParams } from "react-router-dom";

const TimeSelectSection = ({ headerRef }) => {
	// hooks
	const [searchParams] = useSearchParams();

	// ========================|| DATA ||======================== //
	const { currency, setCurrency } = useGlobalContext();
	const dashboardTimePeriod = searchParams.get("timePeriod") || "week";

	const date = useMemo(() => {
		return searchParams.get("date") ? JSON.parse(searchParams.get("date")) : { start: null, end: null };
	}, [searchParams]);

	const [monthSelOpen, setMonthSelOpen] = useState(false);
	const [monthDate, setMonthDate] = useState(null);

	const [anchorEl, setAnchorEl] = useState(null);
	const menuOpen = Boolean(anchorEl);

	// ========================|| FUNCTIONS ||======================== //
	const updateSearchParams = useGlobalContext().updateSearchParams;

	const handleTimePeriodChange = (e) => {
		const data = [{ name: "timePeriod", value: e.target.value }];
		if (e.target.value !== "custom_date") {
			let startDate;
			const endDate = format(new Date(), "yyyy-MM-dd");
			switch (e.target.value) {
				case "day":
					startDate = format(new Date(), "yyyy-MM-dd");
					break;
				case "week":
					startDate = format(startOfWeek(new Date()), "yyyy-MM-dd");
					break;
				case "month":
					startDate = format(startOfMonth(new Date()), "yyyy-MM-dd");
					break;
				case "year":
					startDate = format(startOfYear(new Date()), "yyyy-MM-dd");
					break;
				case "custom_month":
					startDate = format(startOfMonth(new Date()), "yyyy-MM-dd");
					break;
				default:
					startDate = format(startOfWeek(new Date()), "yyyy-MM-dd");
			}
			data.push({ name: "date", value: JSON.stringify({ start: startDate, end: endDate }) });
		}
		updateSearchParams({ data });
	};

	const handleChangeDate = (start, end) => {
		if (isValid(start) && isValid(end)) {
			const newDate = {
				start: format(new Date(start), "yyyy-MM-dd"),
				end: format(new Date(end), "yyyy-MM-dd"),
			};
			updateSearchParams({ data: [{ name: "date", value: JSON.stringify(newDate) }] });
		}
	};

	const handleChangeMonth = useCallback(() => {
		if (isValid(monthDate)) {
			const newDate = {
				start: format(startOfMonth(new Date(monthDate)), "yyyy-MM-dd"),
				end: format(endOfMonth(new Date(monthDate)), "yyyy-MM-dd"),
			};
			updateSearchParams({ data: [{ name: "date", value: JSON.stringify(newDate) }] });
		}
	}, [monthDate, updateSearchParams]);

	const handleClick = (event) => {
		setAnchorEl(headerRef.current);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	// ========================|| USE EFFECTS ||======================== //

	// set default date if date is not set
	useEffect(() => {
		!searchParams.get("date") &&
			updateSearchParams({
				data: [{ name: "date", value: JSON.stringify({ start: format(startOfWeek(new Date()), "yyyy-MM-dd"), end: format(new Date(), "yyyy-MM-dd") }) }],
				replace: true,
			});
	}, [searchParams, updateSearchParams]);

	useEffect(() => {
		setMonthDate(date?.start);
	}, [date]);

	useEffect(() => {
		if (!monthSelOpen) {
			handleChangeMonth();
		}
		// eslint-disable-next-line
	}, [monthSelOpen]);

	// ========================|| RENDER ||======================== //
	return (
		<>
			{/* Small Screen */}
			<Button onClick={handleClick} variant="text" color="primary" sx={{ display: { xs: "inline-flex", lg: "none" }, mr: 2, alignItems: "center" }}>
				Dashboard Filter <KeyboardArrowDown size={16} />
			</Button>
			<Popover open={menuOpen} onClose={handleClose} anchorEl={anchorEl} anchorOrigin={{ vertical: "bottom", horizontal: "right" }} marginThreshold={0} elevation={1}>
				<Stack direction={{ xs: "column", sm: "row" }} spacing={2} sx={{ p: 2, mr: 2 }}>
					<TextField
						sx={{ width: 100 }}
						variant="standard"
						type="text"
						value={currency?.exchangeRate?.gbp2krw}
						label="GBP to KRW"
						onChange={(e) => {
							let value = 0;
							if (!isNaN(e.target.value)) {
								value = Number(e.target.value);
							}
							setCurrency({ ...currency, exchangeRate: { ...currency?.exchangeRate, gbp2krw: value } });
						}}
					/>
					{dashboardTimePeriod !== "custom_month" ? (
						<DateRange date={date} handleDate={handleChangeDate} type="dashboard" disabled={dashboardTimePeriod !== "custom_date"} />
					) : (
						<LocalizationProvider dateAdapter={AdapterDateFns}>
							<DatePicker
								open={monthSelOpen}
								onOpen={() => setMonthSelOpen(true)}
								openTo="month"
								views={["year", "month"]}
								label="Year and Month"
								maxDate={format(new Date(), "yyyy-MM-dd")}
								value={monthDate}
								onChange={(newValue) => {
									isValid(newValue) && setMonthDate(newValue);
								}}
								onClose={() => {
									setMonthSelOpen(false);
								}}
								renderInput={(params) => <TextField {...params} variant="standard" sx={{ width: { xs: "100%", sm: 150 } }} helperText={null} />}
							/>
						</LocalizationProvider>
					)}
					<FormControl variant="standard" sx={{ minWidth: 120, mr: 2 }}>
						<InputLabel id="time-period">Time Period</InputLabel>
						<Select labelId="time-period" label="Time Period" value={dashboardTimePeriod} onChange={handleTimePeriodChange}>
							<MenuItem value="day">Today</MenuItem>
							<MenuItem value="week">This Week</MenuItem>
							<MenuItem value="month">This Month</MenuItem>
							<MenuItem value="year">This Year</MenuItem>
							<MenuItem value="custom_date">Custom Date</MenuItem>
							<MenuItem value="custom_month">Custom Month</MenuItem>
						</Select>
					</FormControl>
				</Stack>
			</Popover>
			{/* Large Screen */}
			<Stack direction={{ xs: "column", sm: "row" }} spacing={2} sx={{ mr: 2, display: { xs: "none", lg: "flex" } }}>
				<TextField
					sx={{ width: 100 }}
					variant="standard"
					type="text"
					value={currency?.exchangeRate?.gbp2krw}
					label="GBP to KRW"
					onChange={(e) => {
						let value = 0;
						if (!isNaN(e.target.value)) {
							value = Number(e.target.value);
						}
						setCurrency({ ...currency, exchangeRate: { ...currency?.exchangeRate, gbp2krw: value } });
					}}
				/>
				{dashboardTimePeriod !== "custom_month" ? (
					<DateRange date={date} handleDate={handleChangeDate} type="dashboard" disabled={dashboardTimePeriod !== "custom_date"} />
				) : (
					<LocalizationProvider dateAdapter={AdapterDateFns}>
						<DatePicker
							open={monthSelOpen}
							onOpen={() => setMonthSelOpen(true)}
							openTo="month"
							views={["year", "month"]}
							label="Year and Month"
							maxDate={format(new Date(), "yyyy-MM-dd")}
							value={monthDate}
							onChange={(newValue) => {
								isValid(newValue) && setMonthDate(newValue);
							}}
							onClose={() => {
								setMonthSelOpen(false);
							}}
							renderInput={(params) => <TextField {...params} variant="standard" sx={{ width: { xs: "100%", sm: 150 } }} helperText={null} />}
						/>
					</LocalizationProvider>
				)}
				<FormControl variant="standard" sx={{ minWidth: 120, mr: 2 }}>
					<InputLabel id="time-period">Time Period</InputLabel>
					<Select labelId="time-period" label="Time Period" value={dashboardTimePeriod} onChange={handleTimePeriodChange}>
						<MenuItem value="day">Today</MenuItem>
						<MenuItem value="week">This Week</MenuItem>
						<MenuItem value="month">This Month</MenuItem>
						<MenuItem value="year">This Year</MenuItem>
						<MenuItem value="custom_date">Custom Date</MenuItem>
						<MenuItem value="custom_month">Custom Month</MenuItem>
					</Select>
				</FormControl>
			</Stack>
		</>
	);
};

export default TimeSelectSection;

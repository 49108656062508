// material
import { Typography } from '@mui/material';
// project imports
import NavGroup from './NavGroup';
import menuItem from 'config/menu-items';
import { checkAuthLevel } from 'helpers/storage';

const MenuList = () => {
  const navItems = menuItem.items.map((item, index) => {
    if ((item.level && item.level === checkAuthLevel()) || !item.level) {
      switch (item.type) {
        case 'group':
          return <NavGroup key={index} item={item} />;
        default:
          return (
            <Typography key={index} variant='h6' color='error' align='center'>
              Menu Items Error
            </Typography>
          );
      }
    }
    return <></>;
  });

  return <>{navItems}</>;
};

export default MenuList;

import { gql } from '@apollo/client';

export const STORE_QUERY = gql`
  query GetBrands($storeId: ID!) {
    currentStoreId @client @export(as: "storeId")
    store(store_id: $storeId) {
      response {
        status
        message
      }
      item {
        name
        key
        ip
        created
      }
    }
  }
`;

export const STORE_LIMIT_QUERY = gql`
  query GetLimit($storeId: ID!, $type: String!) {
    currentStoreId @client @export(as: "storeId")
    storelimit(store_id: $storeId, type: $type) {
      response {
        status
        message
      }
    }
  }
`;

import { gql } from '@apollo/client';

export const BRANDS_QUERY = gql`
  query GetBrands($storeId: ID!) {
    currentStoreId @client @export(as: "storeId")
    brands(store_id: $storeId) {
      response {
        status
        code
        message
      }
      items {
        brand
      }
    }
  }
`;

import { ShoppingCart } from '@mui/icons-material';
import { path } from 'config/path';

const order = {
    type: 'group',
    children: [
        {
            id: 'orders',
            title: 'Orders',
            type: 'item',
            url: path.urls.inventoryOrders,
            icon: ShoppingCart
        }
    ]
}

export default order;
import { gql } from '@apollo/client';

export const QRCODE_INVENTORY_QUERY = gql`
  query GetInventory($itemId: ID!) {
    qrcode_inventory(item_id: $itemId) {
      response {
        status
        code
        message
      }
      item {
        id
        store_key
        brand
        category
        subcategory
        storage
        name
        code
        product_code
        model
        price
        sale_price
        color
        size
        qty
        image
        note
        created
      }
    }
  }
`;

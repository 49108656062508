import { useEffect, useState } from 'react';
// material
import { Box, Button, TextField } from '@mui/material';
import { DateRangePicker, LocalizationProvider } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { addDays } from 'date-fns';
import { DATE_FORMAT } from 'config/constants';
import { Replay } from '@mui/icons-material';
import { Stack } from '@mui/system';

const DateRange = ({ date, handleDate, handleResetDate, isDateSameAsInitDate, type, disabled }) => {
  const [open, setOpen] = useState(false);
  const [value, setValue] = useState([null, null]);

  useEffect(() => {
    if (date) {
      setValue([date.start, date.end]);
    }
  }, [date]);

  useEffect(() => {
    if (open === false && value[0] !== null && value[1] !== null) {
      if (handleDate) handleDate(value[0], value[1]);
    }
    // eslint-disable-next-line
  }, [open]);

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <DateRangePicker
        disabled={disabled}
        open={open}
        value={value}
        mask='__-__-____'
        inputFormat={DATE_FORMAT} //'yyyy-MM-dd'
        onChange={(newValue) => {
          if (newValue[0] !== null && newValue[1] !== null) setValue(newValue);
        }}
        renderInput={(startProps, endProps) => (
          <Stack direction='row' spacing={1} alignItems='center'>
            <TextField
              {...startProps}
              variant={type === "dashboard" ? "standard" : 'outlined'}
              label='START'
              sx={{ width: { xs: '100%', sm: 110 } }}
              onClick={handleOpen}
            />
            {type === "dashboard" ? null : <Box sx={{ mx: 1.2 }}>to</Box>}
            <TextField
              {...endProps}
              variant={type === "dashboard" ? "standard" : 'outlined'}
              label='END'
              sx={{ width: { xs: '100%', sm: 110 } }}
              onClick={handleOpen}
            />
            {isDateSameAsInitDate && handleResetDate && !isDateSameAsInitDate() && (
              <Button
                sx={{ p: 0, minWidth: 0, mr: 1,  }}
                onClick={handleResetDate}
              >
                <Replay />
              </Button>
            )}
          </Stack>
        )}
        onClose={handleClose}
      />
    </LocalizationProvider>
  );
};

export default DateRange;

import { Navigate } from 'react-router-dom';
// helpers
import { checkLoggedIn } from 'helpers/storage';
// config
import { path } from 'config/path';

const PrivateRoute = ({ component: Component }) => {
  if (checkLoggedIn()) {
    return <Component />;
  }
  return <Navigate to={path.urls.login} />;
};

export default PrivateRoute;

import { useState } from 'react';
import PropTypes from 'prop-types';
// graphql
import { useQuery } from '@apollo/client';
import { STORE_LIMIT_QUERY } from 'graphql/query';

const Limit = ({ type, children }) => {
  const [permission, setPermission] = useState(false);
  // graphql
  useQuery(STORE_LIMIT_QUERY, {
    fetchPolicy: 'network-only',
    variables: { type },
    onCompleted: (data) => {
      setPermission(data.storelimit.response.status);
    },
  });

  if (permission) return <>{children}</>;
  return <></>;
};

Limit.propTypes = {
  type: PropTypes.oneOf(['product', 'in_out']).isRequired,
};

export default Limit;

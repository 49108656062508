import { gql } from '@apollo/client';

export const USER_QUERY = gql`
  query GetUser(
    $storeId: ID!
    $storeKey: String!
    $userId: ID!
    $level: String!
  ) {
    currentStoreId @client @export(as: "storeId")
    currentStoreKey @client @export(as: "storeKey")
    user(
      store_id: $storeId
      store_key: $storeKey
      user_id: $userId
      level: $level
    ) {
      response {
        status
        code
        message
      }
      item {
        user
        name
        email
        contact
      }
    }
  }
`;

// config
import {
  ONE_MEGE_TO_BYTE,
  IMAGE_MAX_FILE_SIZE,
  IMAGE_MAIN_MAX_WIDTH,
  IMAGE_MAIN_MAX_HEIGHT,
  IMAGE_MENU_MAX_WIDTH,
  IMAGE_MENU_MAX_HEIGHT,
} from 'config/constants';

export const checkImageTypeAndAlert = (filetype) => {
  if (!checkImageType(filetype)) {
    alert('The image file can be uploaded.');
    return false;
  } else return true;
};
export const checkImageType = (filetype) => {
  if (filetype.match(/image.*/)) {
    return true;
  } else return false;
};

export const checkImageSizeAndAlert = (filesize) => {
  if (!checkImageSize(filesize)) {
    alert(
      `File truncated as it exceeds the ${
        IMAGE_MAX_FILE_SIZE / ONE_MEGE_TO_BYTE
      } MB size limit.`
    );
    return false;
  } else return true;
};
export const checkImageSize = (filesize) => {
  if (filesize <= IMAGE_MAX_FILE_SIZE) {
    return true;
  } else return false;
};

export const previewImageURL = (file, callback) => {
  const reader = new FileReader();
  reader.onload = (event) => {
    callback(event.target.result);
  };
  reader.readAsDataURL(file);
};

const _getImageSize = (img, type) => {
  let width = img.width;
  let height = img.height;
  const max_size = {
    width: IMAGE_MENU_MAX_WIDTH,
    height: IMAGE_MENU_MAX_HEIGHT,
  };
  if (type === 'main') {
    max_size.width = IMAGE_MAIN_MAX_WIDTH;
    max_size.height = IMAGE_MAIN_MAX_HEIGHT;
  }

  if (width > height) {
    if (width > max_size.width) {
      height = height * (max_size.width / width);
      width = max_size.width;
    }
  } else {
    if (height > max_size.height) {
      width = width * (max_size.height / height);
      height = max_size.height;
    }
  }
  return { width, height };
};
export const resizeImage = (file, type, callback, outputType = 'dataurl') => {
  const reader = new FileReader();
  reader.onload = (event) => {
    const img = document.createElement('img');
    img.onload = () => {
      const { width, height } = _getImageSize(img, type);

      // Dynamically create a canvas element
      const canvas = document.createElement('canvas');
      canvas.width = width;
      canvas.height = height;
      const context = canvas.getContext('2d');
      // Actual resizing
      context.drawImage(img, 0, 0, width, height);

      if (outputType === 'dataurl') {
        callback(canvas.toDataURL(file.type));
      } else if (outputType === 'file') {
        context.canvas.toBlob((blob) => {
          const newFile = new File([blob], file.name, { type: blob.type });
          callback(newFile);
        }, file.type);
      }
    };
    img.src = event.target.result;
  };
  reader.readAsDataURL(file);
};

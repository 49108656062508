// assets
import { IconDashboard } from '@tabler/icons';
import { path } from 'config/path';

const dashboard = {
  // title: 'Dashboard',
  type: 'group',
  children: [
    {
      id: 'dashboard',
      title: 'Dashboard',
      type: 'item',
      url: path.urls.dashboard,
      icon: IconDashboard,
      breadcrumbs: false,
    },
  ],
};

export default dashboard;

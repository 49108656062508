import { Link } from 'react-router-dom';
// material
import { ButtonBase } from '@mui/material';
import { path } from 'config/path';
import Logo from 'components/ui/Logo';

const LogoSection = () => (
  <ButtonBase disableRipple component={Link} to="/">
    <Logo />
  </ButtonBase>
);

export default LogoSection;

import { ApolloClient, InMemoryCache, from } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { createUploadLink } from 'apollo-upload-client';
// storage
import { getAuthToken, getAuthStore, getAuthUser } from 'helpers/storage';
import { path } from '../config/path';

const httpLink = createUploadLink({
  uri: path.gqhost,
});

const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const token = getAuthToken();
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : '',
    },
  };
});

const mergeCache = {
  keyArgs: false,
  merge(existing, incoming) {
    return incoming;
  },
};
const cache = new InMemoryCache({
  typePolicies: {
    Query: {
      fields: {
        currentStoreId: {
          read() {
            return getAuthStore().id;
          },
        },
        currentStoreKey: {
          read() {
            return getAuthStore().key;
          },
        },
        currentUserId: {
          read() {
            return getAuthUser().id;
          },
        },
        currentUserName: {
          read() {
            return getAuthUser().name;
          },
        },
        currentUserLevel: {
          read() {
            return getAuthUser().level;
          },
        },
        inventories: mergeCache,
        inventoryinouts: mergeCache,
        inventorywarehouses: mergeCache,
      },
    },
  },
});

const client = new ApolloClient({
  link: from([authLink.concat(httpLink)]),
  cache,
  defaultOptions: {
    watchQuery: {
      fetchPolicy: 'network-only',
    },
  },
});

export default client;

import { Box, Typography, Link, Stack } from "@mui/material";
import { EmailOutlined, Phone } from "@mui/icons-material";
import { useContext } from "react";
import { StoreContext } from "layout/HomeLayout";
import { snsLink } from "layout/HomeLayout/footer";
import { Textsms } from "@mui/icons-material";

const Contact = () => {
  const { store } = useContext(StoreContext); 
  return (
    <Box display='block' sx={{ textAlign:'left', p:'2em'}}>
      <Box>
        <Typography variant="h1" sx={{ color:'darkorange', py:'1em' }}>Contact us</Typography>
        <Typography variant="h4" sx={{ lineHeight:'1.5em', pb:'1em'}}>
          {store.address}
        </Typography>
        <Stack>
          <Box sx={{display:'flex', alignItems:'center', pb:'1em'}}>
            <EmailOutlined/><Typography variant="h4">&nbsp;{store.email}</Typography>
          </Box>
          <Box sx={{display:'flex', alignItems:'center', pb:'1em'}}>
            <Phone/><Typography variant="h4">&nbsp;{store.phone}</Typography>
          </Box> 
          <Box sx={{display:'flex', alignItems:'center'}}>
            <Textsms/><Typography variant="h4">&nbsp;{store.mobile}</Typography>
          </Box> 
        </Stack>
      </Box>
      <Box display='flex' sx={{ py:'2em', alignItems:'center'  }}>
        { snsLink(store.sns, '4em') }&nbsp;{ snsLink(store.sns2, '4em') }
      </Box>
      <Box sx={{ width: '100%', overflow: 'hidden' }}>
        <iframe 
          src={store.map}
          width="100%" 
          height="300" 
          style={{border:0}} 
          allowFullScreen="" 
          loading="lazy" 
          referrerPolicy="no-referrer-when-downgrade"
          sandbox="allow-forms allow-scripts"
        ></iframe>
      </Box>
    </Box>
  )
}

export default Contact;